<template>
  <v-form class="reset-password-form" @submit.prevent="onSubmit" ref="form">
    <InputElement :rules="[rules.required]" v-model="personalNumber" label="Osobní číslo" />

    <v-btn type="submit" depressed color="primary" block class="mb-4">Resetovat heslo</v-btn>
    <div class="text-center">
      <router-link to="/prihlaseni" class="primary--text text-center cursor-pointer">Zpět na
        přihlášení</router-link>
    </div>


  </v-form>
</template>

<script>
import InputElement from "../elements/InputElement.vue";
import validation from "../../use/validation";
import useApiCall from "../../use/apiCall";

export default {
  components: { InputElement },
  setup() {
    const { postData } = useApiCall();

    return { postData };
  },
  data() {
    return {
      personalNumber: "",
      rules: validation,
    };
  },

  methods: {
    async onSubmit() {
      const response = await this.postData(
        "auth/password/reset-code",
        {
          personalNumber: this.personalNumber,
        },
        null,
        true
      );



      if (response === undefined) {
        this.$store.commit("setFormMessagesType", "error");
      } else {
        this.$store.commit("setFormMessagesTexts", [
          "Na váš registrovaný email byly odeslány instrukce pro nastavení nového hesla.",
        ]);
        this.$store.commit("setFormMessagesType", "success");
      }
    },
  },
};
</script>

<style></style>