<template>
  <div class="forgot-password">
    <div class="card">
      <h1>Zapomenuté heslo</h1>
      <div class="mb-4">Zadejte osobní číslo k účtu pro nastavení nového hesla.</div>
      <ResetPasswordForm />
    </div>
  </div>
</template>

<script>
import ResetPasswordForm from "../components/form/ResetPasswordForm.vue";

export default {
  components: { ResetPasswordForm },
};
</script>

<style lang="scss" scoped>
.card {
  margin: auto;
  max-width: 30rem;
}
</style>