<template>
  <div v-if="statistics" class="home container">
    <div class="text-h3 mb-10 grey--text">
      Dobrý den, {{ statistics.firstNameSalutation }}!
    </div>
    <p>Dnes je {{ getLocaleDate(statistics.todayUtc) }}</p>
    <p>V tomto kalendářním měsíci máte celkem:</p>
    <v-container class="my-8 statistics-cards">
      <v-row>
        <v-col class="card mr-4">
          <span class="text-h4 primary--text">
            {{ formatNumber(statistics.plannedMeetingsCount) }}
          </span>
          <div>naplánovaných schůzek</div>
        </v-col>
        <v-col class="card mr-4">
          <span class="text-h4 primary--text">
            {{ formatNumber(statistics.realizedMeetingsCount) }}
          </span>
          <div>realizovaných schůzek</div>
        </v-col>
        <v-col class="card mr-4">
          <span class="text-h4 primary--text">{{
            statistics.ordersCount
          }}</span>
          <div>vytvořených objednávek</div>
        </v-col>
        <v-col class="card mr-4">
          <span class="text-h4 primary--text">
            {{ formatNumber(statistics.productsSoldCount) }}
          </span>
          <div>prodaných služeb</div>
        </v-col>
        <v-col class="card">
          <span class="text-h4 primary--text">
            {{ formatNumber(statistics.netCommission) }}
          </span>
          <span>Kč</span>
          <div>provize za prodané služby</div>
        </v-col>
      </v-row>
    </v-container>
    <p class="mt-4">
      Pro více detailů ke schůzkám přejděte do sekce
      <router-link to="/schuzky">SCHŮZKY</router-link>
    </p>
    <p class="mt-4">
      Pro více detailů k objednávkám a službám přejděte do sekce
      <router-link to="/objednavky">OBJEDNÁVKY</router-link>
    </p>

    <p>
      Pro vyplnění dotazníku zjištění potenciálu zákazníka přejdi do aplikace <a href="https://forms.office.com/e/d4dLBH8bd1" target="_blank" rel="noopener noreferrer">FORMS</a>
    </p>
    <p>
      Pro vyplnění doporučení, které ti dal zákazník na schůzce přejdi do aplikace <a href="https://forms.office.com/e/QGUiPnpVEf" target="_blank" rel="noopener noreferrer">FORMS</a>
    </p>
  </div>
</template>

<script>
import useApiCall from "../use/apiCall";
import useFormatNumber from "../use/formatNumber";

export default {
  name: "Home",
  setup() {
    const { formatNumber } = useFormatNumber();
    const { getData } = useApiCall();
    return { getData, formatNumber };
  },

  data() {
    return {
      statistics: null,
    };
  },

  async mounted() {
    const userId = this.$store.getters.getUserId;
    ({ statistics: this.statistics } = await this.getData(
      "statistics/homepage",
      0,
      100,
      {
        userId: userId,
      }
    ));
  },

  methods: {
    getLocaleDate(date) {
      const formatedDate = new Date(date);
      const czechDate = formatedDate.toLocaleString("cs", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const czechWeekDay = formatedDate.toLocaleString("cs", {
        weekday: "long",
      });

      return `${czechWeekDay}, ${czechDate}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-h3 {
  margin-left: -0.2rem;
}

.statistics-cards {
  margin: 0 -0.15rem;
}
</style>
