<template>
  <div class="products container">
    <section>
      <div class="d-flex justify-space-between">
        <div class="text-h6 grey--text text-uppercase mb-4">
          Seznam produktů
        </div>
      </div>
      <ProductTable showAlsoHiddenItems showEdit> </ProductTable>
    </section>

    <section>
      <div class="d-flex justify-space-between mt-12">
        <div class="text-h6 grey--text text-uppercase mb-4">
          Seznam kategorií
        </div>
      </div>
      <CategoryTable> </CategoryTable>
    </section>

    <section>
      <div class="d-flex justify-space-between mt-12">
        <div class="text-h6 grey--text text-uppercase mb-4">Seznam slev</div>
      </div>
      <DiscountTable showEdit showAlsoHiddenItems />
    </section>
  </div>
</template>

<script>
import CategoryTable from "../components/tables/CategoryTable.vue";
import DiscountTable from "../components/tables/DiscountTable.vue";
import ProductTable from "../components/tables/ProductTable.vue";
import useApiCall from "../use/apiCall";

export default {
  components: { ProductTable, CategoryTable, DiscountTable },
  setup() {
    const { getData } = useApiCall();
    return { getData };
  },
};
</script>

<style></style>
