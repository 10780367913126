<template>
  <form class="meeting-form" @submit.prevent="onSubmit">
    <div class="d-flex wrap">
      <div class="flex-grow-1 mr-4">
        <h3 class="mb-4">Naplánované</h3>
        <div
          class="mb-4"
          v-for="(plannedMeeting, i) in plannedMeetings"
          :key="i"
        >
          <InputElement
            min="0"
            type="number"
            hide-details
            :disabled="disableForm"
            :label="plannedMeeting.label"
            :value="plannedMeeting.value"
            @input="$emit('plannedMeetingUpdate', { index: i, value: $event })"
          />
        </div>
        <v-divider class="mb-4" />
        <div class="text-h5 primary--text text-center">
          {{ totalPlanned }}
          <div class="subtitle-2">Celkem naplánované</div>
        </div>
      </div>

      <div class="flex-grow-1">
        <h3 class="mb-4">Zrealizované</h3>
        <div
          class="mb-4"
          v-for="(realizedMeeting, i) in realizedMeetings"
          :key="i"
        >
          <InputElement
            type="number"
            min="0"
            hide-details
            :disabled="disableForm"
            :label="realizedMeeting.label"
            :value="realizedMeeting.value"
            @input="$emit('realizedMeetingUpdate', { index: i, value: $event })"
          />
        </div>
        <v-divider class="mb-4" />
        <div class="text-h5 primary--text text-center">
          {{ totalRealized }}
          <div class="subtitle-2">Celkem realizované</div>
        </div>
      </div>
    </div>
    <div class="text-right mt-4">
      <v-btn depressed color="primary" type="submit" :disabled="disableForm">
        {{ editForm ? "Upravit" : "Uložit" }}
      </v-btn>
    </div>
  </form>
</template>

<script>
import InputElement from "../elements/InputElement.vue";

export default {
  components: { InputElement },
  props: {
    date: {
      type: String,
      default: "",
    },
    plannedMeetings: {
      type: Array,
      required: true,
    },
    realizedMeetings: {
      type: Array,
      required: true,
    },
    totalPlanned: {
      type: Number,
      required: true,
    },
    totalRealized: {
      type: Number,
      required: true,
    },
    editForm: {
      type: Boolean,
      default: false,
    },
    disableForm: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async onSubmit() {
      this.$emit("meetingFormSubmit");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  width: 100%;
}
</style>
