<template>
    <v-form v-model="valid" class="email-confirmation-form" @submit.prevent="onSubmit" ref="form">
        <v-alert v-if="emailSent" icon="mdi-check-outline" prominent text type="success" class="mb-8">

            Pokud jste zadali své osobní číslo správně a váš email dosud nebyl ověřen, potvrdzovací kód byl znovu odeslán na
            vaši adresu.
        </v-alert>
        <InputElement :rules="[rules.required]" label="Osobní číslo" v-model="personalNumber" />
        <v-btn depressed color="primary" type="submit" class="mb-4" v-if="!emailSent">Zaslat email znovu</v-btn>
        <div class="text-center">
            <router-link to="/prihlaseni" class="primary--text text-center cursor-pointer">Zpět na
                přihlášení</router-link>
        </div>
    </v-form>
</template>
  
<script>
import InputElement from "../elements/InputElement.vue";
import useApiCall from "@/use/apiCall";
import validation from "../../use/validation";

export default {
    name: "LoginForm",
    components: {
        InputElement,
    },

    setup() {
        const { postData } = useApiCall();
        return {
            postData,
        };
    },

    data() {
        return {
            valid: true,
            personalNumber: null,
            rules: validation,
            emailSent: false
        };
    },

    methods: {
        async onSubmit() {


            await this.postData(
                "/users/email-verification/send-code",
                {
                    personalNumber: this.personalNumber,
                },
                null,
                true
            )

            this.emailSent = true

        },
    },
};
</script>
  
<style lang="scss" scoped>
.v-btn {
    width: 100%;
}
</style>
  