<template>
  <div class="calendar">
    <v-date-picker
      :value="date"
      :events="events"
      first-day-of-week="1"
      full-width
      locale="cs"
      @change="$emit('clickOnDate', $event)"
      @update:picker-date="$emit('pickerDateUpdate', $event)"
    ></v-date-picker>
  </div>
</template>

<script>
export default {
  name: "Calendar",
  props: {
    events: {
      type: Array,
      required: false,
    },
    date: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  ::v-deep .v-date-picker-table {
    height: 500px;

    td {
      border: 1px solid gainsboro;
      padding: 0.5rem;
    }
  }
  ::v-deep .v-date-picker-table .v-btn {
    height: 3rem;
    width: 3rem;
    font-size: 1rem;
  }
}
</style>
