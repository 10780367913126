import dateFormating from './dateFormating';

const { beginOfThisMonth, todayDate } = dateFormating();

export default {
  required: (value) => !!value || 'Toto pole je povinné',
  email: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || 'Špatně zadný formát emailu';
  },
  date: (value) => {
    const pattern =
      /^(0?[1-9]|[12]\d|3[01])[.]\s(0?[1-9]|1[0-2])[.]\s(19|20)\d{2}$/;
    return pattern.test(value) || 'Špatný formát data';
  },

  thisMonth: (value) => {
    if (value >= beginOfThisMonth() && value <= todayDate()) {
      return true;
    } else {
      return 'Datum může být nejpozději dnešek';
    }
  },

  dateInFuture: (value) => {
    if (value >= todayDate()) {
      return true;
    } else {
      return 'Nesmí se jednat o datum v minulosti';
    }
  },

  number: (value) => {
    const numberValue = Number(value);
    if(numberValue) {
      return true
    } else {
      return 'Hodnota není číslo '
    }
  },

  numberBellow40: (value) => {
    const numberValue = Number(value);
    if (numberValue <= 40 && numberValue >= 0) {
      return true;
    } else {
      return 'Hodnota nesmí být větší než 40% a menší než 0%';
    }
  },

  max100Char: (value) => {
    if (value) {
      return value?.length <= 100 || 'Maximální počet znaků je 100';
    } else {
      return true;
    }
  },

  max200Char: (value) => {
    if (value) {
      return value?.length <= 200 || 'Maximální počet znaků je 200';
    } else {
      return true;
    }
  },

  max250Char: (value) => {
    if (value) {
      return value?.length <= 250 || 'Maximální počet znaků je 250';
    } else {
      return true;
    }
  },

  max2500Char: (value) => {
    if (value) {
      return value.length <= 2500 || 'Maximální počet znaků je 2 500';
    } else {
      return true;
    }
  },
};
