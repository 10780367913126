import store from '../store';
import checkTokenExpirationToRedirect from '../use/checkTokenExpirationToRedirect';
import catchErrors from './catchErrors';
import axios from 'axios';

export default function () {
  async function getData(url, skip, take, queryParams, isForm) {
    checkTokenExpirationToRedirect();
    if (isForm) {
      const formIsValid = this.$refs.form.validate();
      if (!formIsValid) {
        return;
      }
    }
    try {
      const token = store?.state?.userInfo?.auth?.accessToken;
      const headers = token
        ? {
          headers: {
            Authorization: `Bearer ${store.state.userInfo.auth.accessToken}`,
          },
        }
        : null;

      const { data: response } = await axios.get(url, {
        ...headers,
        params: {
          skip: skip,
          take: take,
          ...queryParams,
        },
      });
      return response;
    } catch (error) {
      catchErrors(error)
    }
  }

  async function getRawData(url, skip, take, queryParams, isForm) {
    checkTokenExpirationToRedirect();
    if (isForm) {
      const formIsValid = this.$refs.form.validate();
      if (!formIsValid) {
        return;
      }
    }
    try {
      const token = store?.state?.userInfo?.auth?.accessToken;
      const headers = token
        ? {
          headers: {
            Authorization: `Bearer ${store.state.userInfo.auth.accessToken}`,
          },
        }
        : null;

      const response = await axios.get(url, {
        responseType: 'blob',
        ...headers,
        params: {
          skip: skip,
          take: take,
          ...queryParams,
        },
      });

      return response;
    } catch (error) {
      catchErrors(error)
    }
  }

  async function postData(url, payload, params, isForm) {
    checkTokenExpirationToRedirect();
    if (isForm) {
      const formIsValid = this.$refs.form.validate();
      if (!formIsValid) {
        return;
      }
    }
    try {
      const token = store?.state?.userInfo?.auth?.accessToken;
      const headers = token
        ? {
          headers: {
            Authorization: `Bearer ${store.state.userInfo.auth.accessToken}`,
          },
          params: {
            ...params,
          },
        }
        : null;

      store.commit('setFormMessagesTexts', null);
      const { data: response } = await axios.post(url, payload, headers);
      return response;
    } catch (error) {
      catchErrors(error)
    }
  }

  async function putData(url, payload, isForm) {
    checkTokenExpirationToRedirect();
    if (isForm) {
      const formIsValid = this.$refs.form.validate();
      if (!formIsValid) {
        return;
      }
    }
    try {
      const token = store?.state?.userInfo?.auth?.accessToken;
      const headers = token
        ? {
          headers: {
            Authorization: `Bearer ${store.state.userInfo.auth.accessToken}`,
          },
        }
        : null;

      store.commit('setFormMessagesTexts', null);
      const { data: response } = await axios.put(url, payload, headers);
      return response;
    } catch (error) {
      catchErrors(error)
    }
  }

  async function deleteData(url, id) {
    checkTokenExpirationToRedirect();
    try {
      const token = store?.state?.userInfo?.auth?.accessToken;
      const headers = token
        ? {
          headers: {
            Authorization: `Bearer ${store.state.userInfo.auth.accessToken}`,
          },
        }
        : null;

      store.commit('setFormMessagesTexts', null);
      const { data: response } = await axios.delete(`${url}/${id}`, {
        ...headers,
      });

      return response;
    } catch (error) {
      catchErrors(error)
    }
  }

  return { getData, postData, putData, deleteData, getRawData };
}
