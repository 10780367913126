<template>
  <div class="card">
    <h1>Změna hesla</h1>
    <ChangePasswordForm />
  </div>
</template>

<script>
import ChangePasswordForm from "../components/form/ChangePasswordForm.vue";
export default {
  components: { ChangePasswordForm },
};
</script>

<style lang="scss" scoped>
.card {
  max-width: 30rem;
  margin: auto;
}
</style>