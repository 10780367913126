<template>
  <v-app-bar app color="white" class="elevation-0">
    <div class="container d-flex align-center">
      <div class="cursor-pointer" @click="$router.push({ name: 'Home' })">
        <span class="primary--text font-weight-bold">DK</span>tel MySales
      </div>
      <v-spacer />
      <v-btn v-if="!isAuthorized" text @click="$router.push({ name: 'Login' })">
        <v-icon left>mdi-account</v-icon> Přihlášení
      </v-btn>
      <v-btn
        v-else
        text
        v-for="(menuItem, i) in menuItems"
        :key="i"
        color="primary"
        @click="
          menuItem.route
            ? $router.push({ name: menuItem.route })
            : menuItem.action()
        "
      >
        <v-icon left>{{ menuItem.icon }}</v-icon> {{ menuItem.name }}
      </v-btn>

      <v-menu v-if="isAuthorized" offset-y :nudge-left="80">
        <template v-slot:activator="{ on }">
          <v-btn class="ml-2" color="primary" v-on="on" icon>
            <v-icon>mdi-account-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link>
            <v-list-item-title @click="$router.push({ name: 'ChangePassword' })"
              >Změna hesla</v-list-item-title
            >
          </v-list-item>
          <v-list-item link>
            <v-list-item-title @click="logOut">Odhlásit se</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import checkTokenExpiration from "../use/checkTokenExpiration";

export default {
  computed: {
    isAuthorized() {
      return checkTokenExpiration();
    },
    menuItems() {
      const role = this.$store.getters.getRole;
      const commonItems = [
        {
          name: "Objednávky",
          route: "Orders",
          icon: "mdi-reorder-horizontal",
        },
        {
          name: "Sazebník",
          route: "Tariffs",
          icon: "mdi-currency-usd",
        },
      ];
      switch (role) {
        case "admin":
          return [
            {
              name: "Správa produktů",
              route: "Products",
              icon: "mdi-playlist-edit",
            },
            {
              name: "Uživatelé",
              route: "Users",
              icon: "mdi-account-group-outline",
            },
            ...commonItems,
          ];
        case "manager":
          return [
            {
              name: "Domů",
              route: "Home",
              icon: "mdi-home-outline",
            },
            {
              name: "Schůzky",
              route: "Meetings",
              icon: "mdi-calendar-blank-outline",
            },
            {
              name: "Uživatelé",
              route: "Users",
              icon: "mdi-account-group-outline",
            },
            ...commonItems,
          ];
        case "assistant":
          return [
            {
              name: "Objednávky",
              route: "Orders",
              icon: "mdi-reorder-horizontal",
            },
          ];
        default:
          return [
            {
              name: "Domů",
              route: "Home",
              icon: "mdi-home-outline",
            },
            {
              name: "Schůzky",
              route: "Meetings",
              icon: "mdi-calendar-blank-outline",
            },
            ...commonItems,
          ];
      }
    },
  },

  methods: {
    logOut() {
      this.$store.commit("setUserInfo", null);
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style></style>
