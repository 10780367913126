<template>
  <v-text-field
    dense
    v-bind="$attrs"
    :value="value"
    @input="(v) => $emit('input', v)"
    :outlined="outlined"
    flat
    :label="label"
    :type="type"
    background-color="white"
    @blur="$emit('blur')"
    @change="$emit('change')"
  ></v-text-field>
</template>

<script>
export default {
  name: "InputElement",
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [Number, String],
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: String,
    },
  },
};
</script>

<style></style>
