<template>
  <div class="tarrifs container">
    <div class="text-h6 grey--text text-uppercase mb-4">
      PODMÍNKY PRO VÝPLATU PROVIZÍ A OBJEMOVÉHO BONUSU
    </div>
    <div>
      <div
        class="text-decoration-underline mt-8 mb-4 font-weight-bold grey--text"
      >
        VÝPLATA PROVIZÍ:
      </div>
      <div class="mb-8">
        <div>Provizovány jsou pouze položky uvedené v sazebníku.</div>
        <div>
          Ochranná lhůta pro všechny položky sazebníku je 9 měsíců od data
          aktivace.
        </div>
        <div>
          V případě zrušení služby před ukončením ochranné lhůty se vrací celá
          výše provize.
        </div>
        <div>
          Z každé přiznané provize se vyplácí 90 %, zbylých 10 % se alokuje do
          fondu na vratky (clawbacky).
        </div>
        <div>
          Nárok na výplatu zbylých 10 % provize vzniká po uplynutí ochranné
          lhůty, jsou-li tou dobou kladná salda fondů na vratky:
        </div>
        <div>1) daného kalendářního měsíce</div>
        <div>
          2) za celé období obchodní činnosti obchodního zástupce, kdy zůstatek
          fondu na vratky je větší než historický podíl vratek OZ (%) krát suma
          vyplacených provizí, které jsou stále v ochranné lhůtě
        </div>

        <div
          class="text-decoration-underline mt-8 mb-4 font-weight-bold mt-4 grey--text"
        >
          PRODEJE, KTERÉ SE NEODMĚŇUJÍ
        </div>
        <div>Prodej postpaid a MBB zákazníkům nad 75 let</div>
        <div>Prodej postpaidu s portací od O2 Family</div>
        <div>Prodej transferu z dobíjecí karty mimo napočet v campro</div>
        <div>Migrace internetu a TV s výjimkou migrace na optiku</div>
        <div>
          Prodej služby, které není v provozu v období 30. - 90. den od data
          aktivace
        </div>

        <div
          class="text-decoration-underline mt-8 mb-4 font-weight-bold mt-4 grey--text"
        >
          OBJEMOVÝ BONUS:
        </div>
        <div>
          Při dosažení stanového počtu prodaných služeb v kalendářním měsíci
          vzniká obchodnímu zástupci nárok na bonus.
        </div>
        <div>
          Prodanou službou (GA) se rozumí taková, za níž náleží obchodnímu
          zástupci čistá provize ve výši minimálně 300 Kč.
        </div>
        <div>Bonusovaná pásma dle počtu GA:</div>
        <div>0-29 GA = 0 Kč</div>
        <div>30-49 GA = 5 000 Kč</div>
        <div>50-69 GA = 10 000 Kč</div>
        <div>70+ GA = 15 000 Kč</div>

        <div>
          Dojde-li před ukončením ochranné lhůty ke zrušení služeb, v jejichž
          důsledku dojde k posunu do nižšího bonusového pásma, vzniká OZ
          povinnost vrátit rozdíl mezi původně vyplacenou částkou bonusu z
          vyššího pásma a částkou za bonus z nižšího pásma, kam se OZ po korekci
          posunul.
        </div>
      </div>
    </div>
    <div class="text-h6 mt-12 grey--text text-uppercase mb-4">
      PROVIZNÍ SAZEBNÍK
    </div>

    <ProductTable class="mb-12" />

    <div class="text-h6 grey--text text-uppercase mb-2">SAZEBNÍK SLEV</div>
    <div class="mb-4 grey--text">
      <strong>DŮLEŽITÉ:</strong> Při použití slevy mimo níže uvedený seznam bude
      započtena záporná provize v plné výši tak, jak ji vyúčtuje O2 Czech
      Republic a.s.
    </div>

    <DiscountTable class="mb-12" />
  </div>
</template>

<script>
import DiscountTable from "../components/tables/DiscountTable.vue";
import ProductTable from "../components/tables/ProductTable.vue";

export default {
  components: { ProductTable, DiscountTable },
  computed: {
    date() {
      const date = new Date();
      const month = date.getMonth();

      const year = date.getFullYear();
      return `1. ${month + 1}. ${year}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.discount-table {
  max-width: 46rem;
}
</style>
