<template>
  <v-form class="set-new-password" ref="form" @submit.prevent="onSubmit">
    <h1>Nastavení nového hesla</h1>
    <PasswordInputElement
      :rules="[rules.required]"
      v-model="password"
      label="Nové heslo"
      type="password"
      @input="checkIfPasswordMatchAgain"
    />
    <PasswordInputElement
      :rules="[rules.required]"
      :error-messages="passwordDontMatch"
      v-model="passwordAgain"
      label="Nové heslo znovu"
      type="password"
      @input="checkIfPasswordMatch"
    />
    <v-btn depressed color="primary" type="submit">Nastavit nové heslo</v-btn>
  </v-form>
</template>

<script>
import PasswordInputElement from "../elements/PasswordInputElement.vue";
import validation from "../../use/validation";
import useApiCall from "../../use/apiCall";

export default {
  components: { PasswordInputElement },
  props: {
    code: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { postData } = useApiCall();
    return {
      postData,
    };
  },
  data() {
    return {
      rules: validation,
      password: null,
      passwordAgain: null,
      passwordDontMatch: null,
    };
  },
  methods: {
    checkIfPasswordMatch() {
      this.passwordAgain === this.password
        ? (this.passwordDontMatch = null)
        : (this.passwordDontMatch = "Hesla nejsou shodná");
    },
    checkIfPasswordMatchAgain() {
      if (!this.passwordAgain) {
        return;
      }
      this.checkIfPasswordMatch();
    },

    async onSubmit() {
      if (this.passwordDontMatch) {
        return;
      }
      const user = await this.postData(
        "auth/password",
        {
          newPassword: this.password,
          recoveryCode: this.code,
        },
        null,
        true
      );
      if (user) {
        this.$store.commit("setUserInfo", user);
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>

<style>
</style>