<template>
  <v-form class="user-add-form" @submit.prevent="onSubmit" ref="form">
    <SelectInputElement v-if="!editUserObject" label="Role" :rules="[rules.required]" v-model="user.role"
      :items="roleOption" item-text="name" item-value="value" />
    <InputElement :rules="[rules.required, rules.max100Char]" v-model="user.firstName" label="Jméno" />
    <InputElement :rules="[rules.required, rules.max100Char]" v-model="user.lastName" label="Příjmení" />
    <InputElement :rules="[rules.required, rules.email]" v-model="user.email" label="E-mail" />
    <InputElement :rules="[rules.required, rules.max200Char]" v-model="user.personalNumber" label="Osobní číslo" />
    <SelectInputElement v-if="showSuperiorUserSelect" v-model="user.superiorUser" :items="superiorUsers"
      item-text="fullName" item-value="id" label="Nadřízený" @change="superiorUserChange(user.superiorUser)" />
    <InputElement :rules="[rules.required, rules.max250Char]" v-model="user.location" label="Lokalita" />
    <InputElement :rules="[rules.required]" v-model="user.startFrom" label="Začátek" :type="'date'"
      pattern="\d{4}-\d{2}-\d{2}" />
    <div class="text-right">
      <v-btn text class="mr-4" @click="closeDialog"> Zavřít </v-btn>
      <v-btn color="primary" depressed @click="onSubmit">
        {{ editUserObject ? "Upravit uživatele" : "Přidat uživatele" }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import InputElement from "../elements/InputElement.vue";
import useApiCall from "@/use/apiCall";
import SelectInputElement from "../elements/SelectInputElement.vue";
import validation from "../../use/validation";
import useDateFormating from "../../use/dateFormating";

export default {
  components: { InputElement, SelectInputElement },
  props: {
    editUserObject: {
      type: Object,
      required: null,
    },
    formIsOpen: {
      type: Boolean,
      default: false,
    },
    superiorUsers: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      rules: validation,
      user: {
        role: null,
        firstName: null,
        lastName: null,
        email: null,
        personalNumber: null,
        location: null,
        startFrom: null,
      },
    };
  },

  computed: {
    roleOption() {
      const userRole = this.$store.getters.getRole;
      return userRole === "admin"
        ? [
          { name: "Obchodní zástupce", value: "representative" },
          { name: "Area Sales Manager", value: "manager" },
          { name: "Asistent", value: "assistant" },
        ]
        : [{ name: "Obchodní zástupce", value: "representative" }];
    },

    currentRole() {
      return this.$store.getters.getRole;
    },

    showSuperiorUserSelect() {
      return this.user.role === 'representative' && this.currentRole === 'admin'
    }
  },

  watch: {
    editUserObject: {
      handler() {
        if (this.editUserObject) {
          this.user = { ...this.editUserObject };
        } else {
          this.$refs.form?.resetValidation();
          this.user = {
            role: null,
            firstName: null,
            lastName: null,
            email: null,
            personalNumber: null,
            location: null,
            startFrom: null,
            superiorUserId: null
          };
        }
      },
      immediate: true,
    },
  },

  setup() {
    const { todayDate } = useDateFormating();
    const { getData, postData, putData } = useApiCall();
    return {
      todayDate,
      postData,
      putData,
      getData
    };
  },

  methods: {
    async onSubmit() {
      if (this.editUserObject) {
        const user = await this.putData(
          `users/${this.editUserObject.id}`,
          { user: this.user },
          true
        );
        if (!user) {
          return;
        }
        this.$emit("userIsUpdated", this.user);
        return;
      }
      const user = await this.postData(
        "users",
        { user: this.user },
        null,
        true
      );
      if (!user) {
        return;
      }
      this.$emit("userIsAdded", this.user);
      this.user = {
        role: null,
        firstName: null,
        lastName: null,
        email: null,
        personalNumber: null,
        location: null,
        startFrom: null,
      };

      this.$refs.form?.resetValidation();
    },



    superiorUserChange(superiorUserId) {
      this.user.superiorUserId = superiorUserId
    },

    closeDialog() {
      this.$refs.form.resetValidation();
      this.$emit("closeBtn");
    },
  },
};
</script>

<style></style>
