<template>
  <div class="category-table">
    <div class="d-flex justify-space-between">
      <div class="d-flex">
        <div class="search-input mr-4">
          <InputElement
            :rules="[rules.max250Char]"
            v-model="searchQuery"
            label="Vyhledat"
            append-icon="mdi-magnify"
            clearable
          />
        </div>
        <v-btn
          text
          depressed
          color="primary"
          @click="clearAllFilters"
          class="mb-4"
        >
          <v-icon left>mdi-filter-variant-remove</v-icon> Vyčistit všechny
          filtry
        </v-btn>
      </div>
      <v-btn color="primary" depressed @click="showCreateCategoryDialog">
        <v-icon left>mdi-plus</v-icon> Přidat kategorii
      </v-btn>
    </div>
    <data-table
      ref="productDataTable"
      class="mb-4"
      v-if="categories"
      :headers="headers"
      :items="categories"
      hide-default-header
      @updateFooterOptions="updateFooterOptions"
      :metadata="metadata"
    >
      <template v-slot:header>
        <thead>
          <tr>
            <th v-for="(header, i) in headers" :key="i">
              <v-menu
                offset-y
                :close-on-content-click="false"
                :disabled="header.disableFilter"
              >
                <template v-slot:activator="{ on }">
                  <div class="d-flex" :class="header.class" v-on="on">
                    {{ header.text }}

                    <v-icon
                      v-if="header.text && !header.disableFilter"
                      small
                      :color="header.filterIsActive ? 'accent' : 'gray'"
                    >
                      mdi-filter-variant
                    </v-icon>
                  </div>
                </template>
                <FilterMenu
                  :value="header.filters"
                  :filterOptions="header.filterOptions"
                  :showSearch="header.showSearch"
                  :showOrderBtn="header.showOrderBtn"
                  :filterType="header.filterType"
                  @filterUpdate="filterUpdate($event, header)"
                  @orderBy="orderBy($event, header)"
                />
              </v-menu>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item.drag>
        <v-icon>mdi-drag</v-icon>
      </template>
      <template v-slot:item.isHidden="{ item }">
        <v-btn icon color="primary" @click="changeHiddenStatus(item)">
          <v-icon>{{ item.isHidden ? "mdi-eye-off" : "mdi-eye" }} </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ getCzechDate(item.createdAt) }}
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ getCzechDate(item.updatedAt) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon color="primary" @click="showEditCategoryDialog(item)"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
      </template>
    </data-table>

    <DialogWindow
      :showDialog="showDialog"
      :dialog="dialog"
      @closeDialog="closeDialog"
      @saveDialog="submit"
    >
      <v-form ref="form" @submit.prevent="onSubmit">
        <InputElement
          :rules="[rules.required]"
          label="Název"
          v-model="editCategory.name"
        />
      </v-form>
    </DialogWindow>
  </div>
</template>

<script>
import DataTable from "../elements/DataTable.vue";
import FilterMenu from "../elements/FilterMenu.vue";
import InputElement from "../elements/InputElement.vue";
import validation from "../../use/validation";
import useApiCall from "../../use/apiCall";
import udeDateFormating from "../../use/dateFormating";
import DialogWindow from "../elements/DialogWindow.vue";

export default {
  components: { DataTable, FilterMenu, InputElement, DialogWindow },
  setup() {
    const { getData, putData, postData } = useApiCall();
    const { getCzechDate } = udeDateFormating();
    return { getData, putData, getCzechDate, postData };
  },

  sortable: null,
  data() {
    return {
      categories: null,
      metadata: null,
      requestTimeOut: null,
      searchQuery: null,
      editCategory: {},
      showDialog: false,
      response: null,
      tariffsParams: {
        isHidden: null,
        orderBy: "nameAsc",
      },
      rules: validation,
      skipCategories: 0,
      takeCategories: 10,
      dialog: {
        title: "",
        text: "",
      },
      headers: [
        {
          text: "Kategorie",
          value: "name",
          filters: "",
          showSearch: false,
          filterIsActive: false,
        },
        {
          text: "Vytvořeno",
          disableFilter: true,
          value: "createdAt",
          filters: "",
          filterIsActive: false,
          showOrderBtn: false,
        },
        {
          text: "Upraveno",
          value: "updatedAt",
          filters: "",
          filterIsActive: false,
          showOrderBtn: false,
          disableFilter: true,
        },
        {
          text: "Viditelný",
          value: "isHidden",
          filters: "",
          filterIsActive: false,
          disableFilter: true,
        },
        {
          text: "",
          value: "action",
          align: "right",
          filters: "",
          filterIsActive: false,
        },
      ],
    };
  },

  watch: {
    searchQuery() {
      if (this.requestTimeOut) {
        clearTimeout(this.requestTimeOut);
      }
      this.requestTimeOut = setTimeout(() => {
        this.searchInTable();
      }, 600);
    },
  },
  async mounted() {
    await this.fetchCategories();
  },
  methods: {
    async updateFooterOptions(event) {
      this.skipCategories = event.page * event.itemsPerPage;
      this.takeCategories = event.itemsPerPage;
      await this.fetchCategories();
    },
    async fetchCategories() {
      const response = await this.getData(
        "product-categories/",
        this.skipCategories,
        this.takeCategories,
        this.tariffsParams
      );
      this.categories = response.categories;
      this.metadata = response.metadata;
    },

    async orderBy(event, header) {
      this.headers.forEach((el) => {
        el.filterIsActive = el.filters;
      });

      header.filterIsActive = true;

      const paramKey = header.value;
      this.tariffsParams.orderBy = event ? `${paramKey}Asc` : `${paramKey}Desc`;
      await this.fetchCategories();
    },

    async clearAllFilters() {
      this.tariffsParams = {
        isHidden: false,
      };
      this.tariffsParams.orderBy = ["nameAsc"];
      this.headers.forEach((el) => {
        el.filters = null;
        el.filterIsActive = false;
      });
      this.skipCategories = 0;
      this.takeCategories = 10;
      this.$refs.productDataTable.footerOptions.page = 0;
      this.$refs.productDataTable.footerOptions.itemsPerPage = 10;

      this.searchQuery = null;
      await this.fetchCategories();
    },

    async searchInTable() {
      this.tariffsParams.searchQuery = this.searchQuery;
      await this.fetchCategories();
    },

    async changeHiddenStatus(item) {
      item.isHidden = !item.isHidden;
      await this.putData(
        `product-categories/${item.id}/${item.isHidden ? "hide" : "restore"}`
      );
      await this.fetchCategories();
    },

    showEditCategoryDialog(item) {
      this.showDialog = true;
      this.dialog.title = `Úprava kategorie ${item.name}`;
      this.editCategory = item;
    },

    showCreateCategoryDialog() {
      this.showDialog = true;
      this.dialog.title = `Přidat kategorii`;
    },

    async submit() {
      const payload = {
        category: {
          name: this.editCategory.name,
        },
      };

      if (this.editCategory.id) {
        await this.updateCategory(payload);
      } else {
        await this.createCategory(payload);
      }

      if (this.response) {
        this.$store.commit("setFormMessagesTexts", [
          `${
            this.editCategory.id ? "Kategorie upravena" : "Kategorie přidána"
          }`,
        ]);
        this.$store.commit("setFormMessagesType", "success");
        await this.fetchCategories();
        this.showDialog = false;
        this.response = null;
      }
    },

    async createCategory(payload) {
      this.response = await this.postData(
        `product-categories`,
        payload,
        null,
        true
      );
    },

    async updateCategory(payload) {
      this.response = await this.putData(
        `product-categories/${this.editCategory.id}`,
        payload
      );
    },

    closeDialog() {
      this.$refs.form.resetValidation();
      this.editCategory = {};
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  width: 20rem;
}
th {
  white-space: nowrap;
  cursor: pointer;
  & :hover {
    color: var(--v-accent-base);
  }
}

.data-table {
  ::v-deep .smallColumn {
    width: 3rem;
  }
}
</style>
