<template>
  <v-autocomplete
    v-bind="$attrs"
    dense
    background-color="white"
    no-data-text="Nebyla nalezena shoda"
    :outlined="outlined"
    :value="value"
    @input="(v) => $emit('input', v)"
    @blur="$emit('blur')"
    @change="$emit('change')"
    @keydown.enter.native.prevent
    @update:search-input="$emit('updateChange')"
  >
    <!-- pass through scoped slots -->
    <template
      v-for="(_, scopedSlotName) in $scopedSlots"
      v-slot:[scopedSlotName]="slotData"
    >
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>

    <!-- pass through normal slots -->
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Number, Array, Object],
      default: "",
    },
  },
};
</script>

<style></style>
