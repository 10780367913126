<template>
  <div class="user-table">
    <div class="data-table-header d-flex justify-space-between">
      <div>
        <div class="text-h6 grey--text text-uppercase mb-4">
          Přehled uživatelů
        </div>
      </div>
      <v-btn @click="opedDialog" depressed color="primary">
        <v-icon left>mdi-plus</v-icon> Přidat uživatele
      </v-btn>
    </div>
    <div class="search-input">
      <InputElement :rules="[rules.max250Char]" v-model="searchQuery" label="Vyhledat" append-icon="mdi-magnify"
        clearable />
    </div>
    <DataTable v-if="users" :items="users" :headers="headers" :metadata="usersTableMetadata"
      @updateFooterOptions="updateFooterOptions" :item-class="rowClass">
      <template v-slot:item.emailVerified="{ item }">
        {{ item.emailVerified ? "ano" : "ne" }}
      </template>
      <template v-slot:item.role="{ item }">
        {{ translateRole(item.role) }}
      </template>
      <template v-slot:item.startFrom="{ item }">
        {{ getShortDate(item.startFrom) }}
      </template>
      <template v-slot:item.lock="{ item }">
        <v-btn v-if="item.role !== 'admin'" icon color="primary" @click="lockUser(item)">
          <v-icon>{{
            `mdi-lock${item.isLocked ? "" : "-open-outline"}`
          }}</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn icon color="primary" @click="editUser(item)">
          <v-icon>{{ `mdi-pencil-outline` }}</v-icon>
        </v-btn>
      </template>
    </DataTable>
    <v-dialog v-model="showDialog" max-width="500" persistent>
      <div class="card">
        <div class="text-h4 mb-6">
          {{ editUserObject ? "Upravit uživatele" : "Přidat uživatele" }}
        </div>
        <UserAddForm :superiorUsers="superiorUsers" :editUserObject="editUserObject" @userIsAdded="userIsAdded"
          @userIsUpdated="userIsUpdated" @closeBtn="closeDialog" />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import UserAddForm from "../../components/form/UserAddForm.vue";
import useApiCall from "@/use/apiCall";
import DataTable from "../../components/elements/DataTable.vue";
import InputElement from "../elements/InputElement.vue";
import useDateFormating from "../../use/dateFormating.js";
import validation from "../../use/validation";

export default {
  components: { UserAddForm, DataTable, InputElement },
  setup() {
    const { getData, putData } = useApiCall();
    const { getShortDate } = useDateFormating();
    return {
      getData,
      putData,
      getShortDate,
    };
  },

  data() {
    return {
      rules: validation,
      editUserObject: null,
      requestTimeOut: null,
      showDialog: false,
      users: null,
      usersTableMetadata: null,
      skip: 0,
      take: 10,
      searchQuery: null,
      superiorUsers: [],
      headers: [
        {
          text: "Jméno",
          value: "firstName",
          sortable: false,
        },
        {
          text: "Příjmení",
          value: "lastName",
          sortable: false,
        },
        {
          text: "Osobní číslo",
          value: "personalNumber",
          sortable: false,
        },
        {
          text: "Role",
          value: "role",
          sortable: false,
        },
        {
          text: "E-mail",
          value: "email",
          sortable: false,
        },
        {
          text: "Lokalita",
          value: "location",
          sortable: false,
        },
        {
          text: "Dokončená registrace",
          value: "emailVerified",
          sortable: false,
        },
        {
          text: "Nadřízený",
          value: "superiorUser.fullName",
          sortable: false,
        },
        {
          text: "Začátek",
          value: "startFrom",
          sortable: false,
        },
        {
          text: "",
          value: "edit",
          sortable: false,
        },
        {
          text: "",
          value: "lock",
          sortable: false,
        },
      ],
    };
  },

  watch: {
    searchQuery() {
      if (this.requestTimeOut) {
        clearTimeout(this.requestTimeOut);
      }
      this.requestTimeOut = setTimeout(() => {
        this.searchInTable();
      }, 600);
    },
  },

  async mounted() {
    await this.fetchUsers(null);
  },

  methods: {
    async fetchUsers(searchQuery) {
      const userId = this.$store.getters.getUserId;
      const userRole = this.$store.getters.getRole;

      const payload = {
        searchQuery: searchQuery,
      };

      if (userRole !== "admin") {
        payload.SuperiorUserId = userId;
      }

      if (this.searchQuery?.length > 250) {
        return;
      }

      const response = await this.getData("users", this.skip, this.take, {
        ...payload,
      });
      this.users = response.users;
      this.usersTableMetadata = response.metadata;
    },

    async userIsUpdated(user) {
      this.showDialog = false;
      await this.fetchUsers();
      await this.$store.commit("setFormMessagesTexts", [
        `Uživatel ${user.firstName} ${user.lastName} byl upraven`,
      ]);
      await this.$store.commit("setFormMessagesType", "success");
    },

    async userIsAdded(user) {
      this.showDialog = false;
      await this.fetchUsers();
      await this.$store.commit("setFormMessagesTexts", [
        `Uživatel ${user.firstName} ${user.lastName} byl přidán`,
      ]);
      await this.$store.commit("setFormMessagesType", "success");
    },

    async lockUser(user) {
      await this.putData(
        `users/${user.id}/${user.isLocked ? "unlock" : "lock"}`
      );
      await this.fetchUsers();
    },

    async getSuperiorUsers() {
      const userRole = this.$store.getters.getRole;
      if (userRole !== "admin") {
        return;
      }
      try {
        const { users } = await this.getData(
          `users`
        );
        this.superiorUsers = users.filter((user) => user.role === "manager" || user.role === "admin")
      } catch (error) {
        console.log(error)

      }

    },

    async editUser(item) {
      await this.getSuperiorUsers();
      this.editUserObject = item;
      this.showDialog = true;
    },

    rowClass(item) {
      return item.isLocked ? "lock-row" : "";
    },

    translateRole(role) {
      switch (role) {
        case "manager":
          return "Manažer";
        case "representative":
          return "Obchodní zástupce";
        case "assistant":
          return "Asistent";
        default:
          return "Admin";
      }
    },
    async updateFooterOptions(event) {
      this.skip = event.page * event.itemsPerPage;
      this.take = event.itemsPerPage;
      await this.fetchUsers(this.searchQuery);
    },

    async searchInTable() {
      await this.fetchUsers(this.searchQuery);
    },

    async opedDialog() {
      await this.getSuperiorUsers();
      this.showDialog = true;
      this.editUserObject = null;
    },

    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin: auto;
  background: white;
}

.user-table {
  ::v-deep .lock-row {
    color: rgb(188, 188, 188);
  }
}
</style>
