<template>
    <div class="container">
        <div class="email-confirmation card ma-auto">
            <h1>Znovu zaslat potvrzovací e-mail</h1>
            <div class="mb-4">Pokud si přejete získat nový potvrzovací kód pro již vytvořený účet, prosím, vložte své osobní číslo spojené s tímto účtem.</div>
            <EmailConfirmationForm />
        </div>
    </div>
</template>

<script>
import EmailConfirmationForm from '../components/form/EmailConfirmationForm.vue';

export default {
    components: { EmailConfirmationForm },
};

</script>

<style lang="scss" scoped>
.email-confirmation.card {
    max-width: 30rem;
    word-break: normal;
}
</style>