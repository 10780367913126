<template>
  <div>
    <div class="text-h6 grey--text text-uppercase mb-4">Aktivita uživatelů</div>
    <DataTable
      v-if="logs"
      :headers="headers"
      :items="logs"
      must-sort
      @tableSort="tableSort"
      :metadata="metadata"
      :sortDesc="false"
      class="user-activity-table"
      @updateFooterOptions="updateFooterOptionsOrders"
    >
      <template v-slot:item.occurredAt="{ item }">
        {{ getCzechDate(item.occurredAt) }}
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "../elements/DataTable.vue";
import useApiCalls from "../../use/apiCall";
import useDateFormating from "../../use/dateFormating";

export default {
  components: { DataTable },
  setup() {
    const { getData } = useApiCalls();
    const { getCzechDate } = useDateFormating();
    return { getData, getCzechDate };
  },

  data() {
    return {
      logs: null,
      metadata: null,
      skip: 0,
      take: 10,
      params: {
        orderBy: "occurredAtDesc",
      },
      headers: [
        {
          text: "Jméno",
          value: "actor.fullName",
          sortable: false,
        },
        {
          text: "Osobní číslo",
          value: "actor.personalNumber",
          sortable: false,
        },
        {
          text: "Log",
          value: "occurredAt",
        },
      ],
    };
  },

  async mounted() {
    await this.fetchLogs();
  },

  methods: {
    async fetchLogs() {
      const response = await this.getData(
        "users/activity-logs",
        this.skip,
        this.take,
        this.params
      );
      this.logs = response.logs;
      this.metadata = response.metadata;
    },
    async updateFooterOptionsOrders(event) {
      this.skip = event.page * event.itemsPerPage;
      this.take = event.itemsPerPage;
      await this.fetchLogs();
    },

    async tableSort(isDesc) {
      this.params.orderBy = isDesc ? "occurredAtDesc" : "occurredAtAsc";
      await this.fetchLogs();
    },
  },
};
</script>

<style></style>
