<template>
  <div class="date-picker">
    <v-menu
      ref="menu"
      v-model="datePickerModal"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="displayDate"
          label="Filtr data"
          prepend-icon="mdi-calendar"
          readonly
          clearable
          outlined
          hide-details
          dense
          background-color="white"
          v-bind="attrs"
          v-on="on"
          @click:clear="clearDates"
        ></v-text-field>
      </template>
      <v-date-picker
        range
        v-model="date"
        no-title
        scrollable
        @input="datePickerUpdate"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="datePickerModal = false">
          Zavřít
        </v-btn>
        <v-btn text color="primary" @click="saveDates(date)"> Vybrat </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import useDateFormating from "../../use/dateFormating";

export default {
  name: "InputDatePicker",
  setup() {
    const { getShortDate, todayDate } = useDateFormating();
    return {
      getShortDate,
      todayDate,
    };
  },
  data() {
    return {
      datePickerModal: false,
      date: [],
    };
  },

  computed: {
    displayDate() {
      if (!this.date[0] || !this.date[1]) {
        return "";
      }

      return `${this.getShortDate(this.date[0])} - ${this.getShortDate(
        this.date[1]
      )}`;
    },
  },

  methods: {
    datePickerUpdate() {
      if (this.date[0] > this.date[1]) {
        let first = this.date[1];
        this.date[1] = this.date[0];
        this.date[0] = first;
      }
    },

    saveDates(date) {
      this.$refs.menu.save(date);
      this.$emit("saveDates", date);
    },

    clearDates() {
      this.date = [];
      this.$emit("saveDates", [this.todayDate(), this.todayDate()]);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>