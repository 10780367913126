<template>
  <div class="orders container">
    <section v-if="userRole !== 'admin' && userRole !== 'assistant'">
      <div class="text-h6 mb-4 grey--text">PŘIDAT OBJEDNÁVKU</div>
      <div v-if="!products" class="white pa-8">
        <v-row>
          <v-col v-for="(n, i) in 4" :key="i">
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(n, i) in 4" :key="i">
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(n, i) in 4" :key="i">
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
          </v-col>
        </v-row>
        <div class="d-flex mt-8 ml-4">
          <v-skeleton-loader
            v-for="(n, i) in 2"
            class="mr-4"
            :key="i"
            width="150"
            height="40"
            type="image"
          ></v-skeleton-loader>
        </div>
      </div>
      <AddOrderForm
        v-else
        :categories="categories"
        :discounts="discounts"
        :o2Together="o2Together"
        :order="order"
        :products="products"
        :calculatedOrder="calculatedOrder"
        :loading="loading"
        @orderForm="orderForm"
        @selectCategory="selectCategory"
        @discountTypeChange="discountTypeChange"
        @orderFormSubmit="orderFormSubmit"
        @calcuteForm="calcuteForm"
        @resetForm="resetForm"
      />
    </section>
    <section>
      <div class="text-h6 mb-4 grey--text">
        SOUHRNNÝ PŘEHLED OBJEDNÁVEK A SCHŮZEK
      </div>
      <MeetingsStatistic ref="meetingStatisticComponent" />
    </section>
    <section v-if="userRole !== 'assistant'">
      <div class="text-h6 mb-4 grey--text">DETAILNÍ PŘEHLED VÝKONŮ</div>
      <OrderTable
        ref="orderTableComponent"
        :categories="categories"
        :o2Together="o2Together"
      />
    </section>
  </div>
</template>

<script>
import AddOrderForm from "../components/form/AddOrderForm.vue";
import MeetingsStatistic from "../components/MeetingsStatistic.vue";
import OrderTable from "../components/tables/OrderTable.vue";
import useApiCalls from "../use/apiCall";

export default {
  components: {
    AddOrderForm,
    MeetingsStatistic,
    OrderTable,
  },
  setup() {
    const { getData, postData } = useApiCalls();
    return { getData, postData };
  },
  data() {
    return {
      loading: false,
      categories: null,
      productCategoryId: null,
      products: null,
      discounts: [],
      deleteItem: null,
      order: {
        orderedAt: null,
        orderNumber: null,
        phoneNumber: null,
        productId: null,
        hwbDiscount: null,
        lcoDiscount: null,
        isTransfer: null,
        o2TogetherLevel: null,
        proDiscountPercentage: null,
        comment: null,
        discountsIds: [],
      },
      o2Together: [1, 2, 3, 4, 5, 6],
      calculatedOrder: {
        calculatedHwbDiscount: 0,
        calculatedLcoDiscount: 0,
        calculatedCurrentCommission: 0,
        calculatedDiscountsSum: 0,
        calculatedProDiscount: 0,
        calculatedNetCommission: 0,
      },
    };
  },
  computed: {
    userRole() {
      return this.$store.getters.getRole;
    },
  },
  async mounted() {
    if (this.userRole === "assistant") {
      return;
    }
    await this.fetchProducts(null);
    await this.fetchDiscounts();
    await this.fetchCategories();
  },
  methods: {
    async fetchCategories() {
      ({ categories: this.categories } = await this.getData(
        "product-categories",
        0,
        null,
        {
          isHidden: false,
        }
      ));
    },
    async fetchProducts(categoryId) {
      ({ products: this.products } = await this.getData("products", 0, null, {
        isHidden: false,
        productCategoryId: categoryId,
      }));
    },
    async fetchDiscounts() {
      ({ discounts: this.discounts } = await this.getData(
        "discounts",
        0,
        null,
        {
          isHidden: false,
        }
      ));
    },
    async selectCategory(event) {
      await this.fetchProducts(event?.id);
      this.calculatedOrder = {
        calculatedHwbDiscount: 0,
        calculatedLcoDiscount: 0,
        calculatedCurrentCommission: 0,
        calculatedDiscountsSum: 0,
        calculatedProDiscount: 0,
        calculatedNetCommission: 0,
      };
    },
    async discountTypeChange() {
      this.order.o2TogetherLevel = null;
      this.order.discountsIds = [];
      this.order.discounts = [];
      this.order.proDiscountPercentage = null;

      if (this.order.productId) {
        await this.calcuteForm();
      }
    },
    async calcuteForm() {
      const todayDate = new Date();
      const month = todayDate.getMonth() + 1;
      const day = todayDate.getDate();
      const year = todayDate.getFullYear();
      const date = `${year}-${this.padTo2Digits(month)}-${this.padTo2Digits(
        day
      )}`;

      const dryRunOrder = {
        orderedAt: date,
        orderNumber: "eY5oFNqgO0m03H24Nkia8A==",
        phoneNumber: "gQ+SSO/JMEyYyJDDUqWBTg==",
        hwbDiscount: this.order.hwbDiscount,
        lcoDiscount: this.order.lcoDiscount,
        productId: this.order.productId,
        isTransfer: this.order.isTransfer,
        o2TogetherLevel: this.order.o2TogetherLevel,
        proDiscountPercentage: this.order.proDiscountPercentage,
        comment: null,
        discountsIds: this.order.discountsIds,
      };
      ({ order: this.calculatedOrder } = await this.postData(
        "orders",
        { order: dryRunOrder },
        { isDryRun: true }
      ));
    },
    async orderFormSubmit() {
      this.loading = true;

      ({ order: this.calculatedOrder } = await this.postData("orders", {
        order: this.order,
      }));

      this.loading = false;

      await this.$refs.orderTableComponent.fetchOrders();
      await this.$refs.meetingStatisticComponent.fetchStatistics();
      await this.$store.commit("setFormMessagesTexts", [
        `Objednávka číslo ${this.order.orderNumber} s produktem ${this.order.product.name}  byla uložena`,
      ]);
      await this.$store.commit("setFormMessagesType", "success");
    },

    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },

    async orderForm(event) {
      this.order = event;
      if (this.order.discounts) {
        this.order.discountsIds = this.order.discounts.map((e) => e.id);
      }

      if (this.order.product) {
        this.order.productId = this.order.product.id;
      } else {
        this.order.productId = null;
        this.calculatedOrder = {
          calculatedHwbDiscount: 0,
          calculatedLcoDiscount: 0,
          calculatedCurrentCommission: 0,
          calculatedDiscountsSum: 0,
          calculatedProDiscount: 0,
          calculatedNetCommission: 0,
        };
      }
    },

    resetForm() {
      this.order = {
        orderedAt: null,
        orderNumber: null,
        phoneNumber: null,
        productId: null,
        isTransfer: null,
        o2TogetherLevel: null,
        proDiscountPercentage: null,
        comment: null,
        discountsIds: [],
      };
      this.calculatedOrder = {
        calculatedHwbDiscount: 0,
        calculatedLcoDiscount: 0,
        calculatedCurrentCommission: 0,
        calculatedDiscountsSum: 0,
        calculatedProDiscount: 0,
        calculatedNetCommission: 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  margin-bottom: 5rem;
}
</style>
