<template>
  <div class="product-table">
    <div class="d-flex justify-space-between">
      <div class="d-flex">
        <div class="search-input mr-4">
          <InputElement :rules="[rules.max250Char]" v-model="searchQuery" label="Vyhledat" append-icon="mdi-magnify"
            clearable />
        </div>
        <v-btn text depressed color="primary" @click="clearAllFilters" class="mb-4">
          <v-icon left>mdi-filter-variant-remove</v-icon> Vyčistit všechny
          filtry
        </v-btn>
      </div>
      <v-btn v-if="showEdit" color="primary" depressed @click="showProductEditDialog">
        <v-icon left>mdi-plus</v-icon> Přidat produkt
      </v-btn>
    </div>
    <data-table ref="productDataTable" class="mb-4" v-if="products" :headers="headers" :items="products"
      hide-default-header @updateFooterOptions="updateFooterOptions" :metadata="metadata">
      <template v-slot:header>
        <thead>
          <tr>
            <th v-for="(header, i) in headers" :key="i">
              <v-menu offset-y :close-on-content-click="false" :disabled="header.disableFilter">
                <template v-slot:activator="{ on }">
                  <div class="d-flex" :class="header.class" v-on="on">
                    {{ header.text }}

                    <v-icon v-if="header.text && !header.disableFilter" small
                      :color="header.filterIsActive ? 'accent' : 'gray'">
                      mdi-filter-variant
                    </v-icon>
                  </div>
                </template>
                <FilterMenu :value="header.filters" :filterOptions="header.filterOptions" :showSearch="header.showSearch"
                  :showOrderBtn="header.showOrderBtn" :filterType="header.filterType"
                  @filterUpdate="filterUpdate($event, header)" @orderBy="orderBy($event, header)"
                  @filterSelectUpdate="filterSelectUpdate($event, header)" />
              </v-menu>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item.drag>
        <v-icon>mdi-drag</v-icon>
      </template>
      <template v-slot:item.commission="{ item }">
        {{ formatNumber(item.commission) }}
      </template>
      <template v-slot:item.o2TogetherLevel1Commission="{ item }">
        {{
          item.o2TogetherLevel1Commission !== null
          ? item.o2TogetherLevel1Commission
          : "-"
        }}
      </template>
      <template v-slot:item.o2TogetherLevel2Commission="{ item }">
        {{
          item.o2TogetherLevel2Commission !== null
          ? item.o2TogetherLevel2Commission
          : "-"
        }}
      </template>
      <template v-slot:item.o2TogetherLevel3Commission="{ item }">
        {{
          item.o2TogetherLevel3Commission !== null
          ? item.o2TogetherLevel3Commission
          : "-"
        }}
      </template>
      <template v-slot:item.o2TogetherLevel4Commission="{ item }">
        {{
          item.o2TogetherLevel4Commission !== null
          ? item.o2TogetherLevel4Commission
          : "-"
        }}
      </template>
      <template v-slot:item.o2TogetherLevel5Commission="{ item }">
        {{
          item.o2TogetherLevel5Commission !== null
          ? item.o2TogetherLevel5Commission
          : "-"
        }}
      </template>

      <template v-slot:item.o2TogetherLevel6Commission="{ item }">
        {{
          item.o2TogetherLevel6Commission !== null
          ? item.o2TogetherLevel6Commission
          : "-"
        }}
      </template>

      <template v-slot:item.isService="{ item }">
        {{
          item.isService
          ? "ano"
          : "ne"
        }}
      </template>
      <template v-slot:item.isHidden="{ item }">
        <v-btn icon color="primary" @click="changeHiddenStatus(item)">
          <v-icon>{{ item.isHidden ? "mdi-eye-off" : "mdi-eye" }} </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon color="primary" @click="showProductEditDialog(item)"><v-icon>mdi-pencil</v-icon></v-btn>
      </template>
    </data-table>
    <DialogWindow :showDialog="showDialog" :dialog="dialog" hideButtons>
      <ProductDetail ref="productDetail" :productId="editProduct.id" @closeDialog="closeProductDialog"
        @productChanged="productChanged" />
    </DialogWindow>
  </div>
</template>

<script>
import DataTable from "../elements/DataTable.vue";
import FilterMenu from "../elements/FilterMenu.vue";
import InputElement from "../elements/InputElement.vue";
import validation from "../../use/validation";
import useApiCall from "../../use/apiCall";
import useFormatNumber from "../../use/formatNumber";
import DialogWindow from "../elements/DialogWindow.vue";
import ProductDetail from "../../views/ProductDetail.vue";

import Sortable from "sortablejs";

export default {
  components: {
    DataTable,
    FilterMenu,
    InputElement,
    DialogWindow,
    ProductDetail,
  },

  sortable: null,

  setup() {
    const { formatNumber } = useFormatNumber();
    const { getData, putData } = useApiCall();
    return { getData, formatNumber, putData };
  },
  data() {
    return {
      products: null,
      metadata: null,
      requestTimeOut: null,
      searchQuery: null,
      showDialog: false,
      editProduct: {},
      dialog: {
        title: "",
      },
      tariffsParams: {
        isHidden: false,
      },
      rules: validation,
      orderByParam: ["ProductCategoryNameAsc", "orderAsc"],
      skipProducts: 0,
      takeProducts: 10,
      headers: [
        {
          text: "Kategorie",
          value: "productCategoryName",
          filters: "",
          filterIsActive: false,
        },
        {
          text: "Produkt",
          value: "name",
          filters: "",
          filterIsActive: false,
        },
        {
          text: "Provize",
          align: "right",
          value: "commission",
          filters: "",
          filterIsActive: false,
          showSearch: false,
          class: "justify-end",
        },
        {
          text: "Ve spolu 1",
          align: "right",
          value: "o2TogetherLevel1Commission",
          filters: "",
          filterIsActive: false,
          class: "justify-end",
        },
        {
          text: "Ve spolu 2",
          align: "right",
          value: "o2TogetherLevel2Commission",
          filters: "",
          filterIsActive: false,
          class: "justify-end",
        },
        {
          text: "Ve spolu 3",
          align: "right",
          value: "o2TogetherLevel3Commission",
          filters: "",
          filterIsActive: false,
          class: "justify-end",
        },
        {
          text: "Ve spolu 4",
          align: "right",
          value: "o2TogetherLevel4Commission",
          filters: "",
          filterIsActive: false,
          class: "justify-end",
        },
        {
          text: "Ve spolu 5",
          align: "right",
          value: "o2TogetherLevel5Commission",
          class: "justify-end",
        },
        {
          text: "Ve spolu 6",
          align: "right",
          value: "o2TogetherLevel6Commission",
          class: "justify-end",
        },
        {
          text: "Započítává se jako služba",
          align: "right",
          value: "isService",
          class: "justify-end",
          showSearch: false,
          showOrderBtn: false,
          filterOptions: [
            { text: "Ano", value: true },
            { text: "Ne", value: false },
          ],
        },
      ],
    };
  },

  props: {
    showEdit: {
      type: Boolean,
      default: false,
    },
    showAlsoHiddenItems: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    searchQuery() {
      if (this.requestTimeOut) {
        clearTimeout(this.requestTimeOut);
      }
      this.requestTimeOut = setTimeout(() => {
        this.searchInTable();
      }, 600);
    },
  },

  computed: {
    cursor() {
      return this.showEdit ? "grab" : "default";
    },
  },

  async mounted() {
    if (this.showAlsoHiddenItems) {
      this.tariffsParams = {
        isHidden: null,
      };
    }
    await this.fetchProducts();
    if (this.showEdit) {
      this.headers.unshift(
        {
          text: "",
          value: "drag",
          disableFilter: true,
          cellClass: "smallColumn",
          align: "right",
        },
        {
          text: "",
          value: "order",
          disableFilter: true,
          cellClass: "smallColumn",
          align: "right",
        }
      );

      if (this.showEdit) {
        this.headers.push(
          {
            text: "Viditelný",
            value: "isHidden",
            filters: "",
            filterIsActive: false,
            disableFilter: true,
            align: "right",
            class: "justify-end",
          },
          {
            text: "",
            value: "action",
            align: "right",
            filters: "",
            filterIsActive: false,
          }
        );
      }

      const tbody = document.querySelector(".product-table tbody");
      this.sortable = new Sortable(tbody, {
        animation: 150,
        ghostClass: "primary--text",
        onEnd: async (e) => {
          if (e.oldIndex === undefined || e.newIndex === undefined) return;
          const dragged = this.products[e.oldIndex];
          const newOrder = e.newIndex + this.skipProducts;
          const id = dragged.id;
          if (!id) return;
          if (dragged.order === newOrder + 1) return;
          this.editProduct.id = id;

          await this.updateProduct({
            product: {
              ...dragged,
              order: newOrder + 1,
            },
          });
          await this.fetchProducts();
        },
      });
    }
  },
  methods: {
    async updateFooterOptions(event) {
      this.skipProducts = event.page * event.itemsPerPage;
      this.takeProducts = event.itemsPerPage;
      await this.fetchProducts();
    },
    async fetchProducts() {
      this.tariffsParams.orderBy = { ...this.orderByParam };
      const response = await this.getData(
        "products",
        this.skipProducts,
        this.takeProducts,
        this.tariffsParams
      );
      this.products = response.products;
      this.metadata = response.metadata;
    },

    async filterUpdate(event, header) {
      header.filters = event;
      header.filterIsActive = event;

      const paramKey = header.value;
      if (this.requestTimeOut) {
        clearTimeout(this.requestTimeOut);
      }

      this.tariffsParams[`searchIn${paramKey}`] = event;
      this.requestTimeOut = setTimeout(() => {
        this.fetchProducts();
      }, 600);
    },

    transferHeaderValueToParamKey(value) {
      switch (value) {
        case "tariffs.isService":
          return "IsService";
        default:
          return value;
      }
    },

    async filterSelectUpdate(event, header) {
      console.log(event)
      const paramKey = this.transferHeaderValueToParamKey(header.value);
      this.tariffsParams[paramKey] = event;
      header.filters = event;
      header.filterIsActive = event !== null;
      await this.fetchProducts();
    },


    async orderBy(event, header) {
      this.headers.forEach((el) => {
        el.filterIsActive = el.filters;
      });

      header.filterIsActive = true;

      const paramKey = header.value;
      this.orderByParam = event ? [`${paramKey}Asc`] : [`${paramKey}Desc`];
      await this.fetchProducts();
    },

    async clearAllFilters() {
      if (this.showAlsoHiddenItems) {
        this.tariffsParams = {
          isHidden: null,
        };
      } else {
        this.tariffsParams = {
          isHidden: false,
        };
      }
      this.orderByParam = ["ProductCategoryNameAsc", "orderAsc"];
      this.headers.forEach((el) => {
        el.filters = null;
        el.filterIsActive = false;
      });
      this.skipProducts = 0;
      this.takeProducts = 10;
      this.$refs.productDataTable.footerOptions.page = 0;
      this.$refs.productDataTable.footerOptions.itemsPerPage = 10;

      this.searchQuery = null;
      await this.fetchProducts();
    },

    async searchInTable() {
      this.tariffsParams.searchQuery = this.searchQuery;
      await this.fetchProducts();
    },

    async productChanged() {
      this.editProduct.id = null;
      this.showDialog = false;
      await this.fetchProducts();
    },

    async changeHiddenStatus(item) {
      item.isHidden = !item.isHidden;
      await this.putData(
        `products/${item.id}/${item.isHidden ? "hide" : "restore"}`
      );
      await this.fetchProducts();
    },

    async updateProduct(payload) {
      this.response = await this.putData(
        `products/${this.editProduct.id}`,
        payload
      );
    },

    clickOnRow(event) {
      this.$emit("clickOnRow", event);
    },
    showProductEditDialog(item) {
      if (item.name) {
        this.dialog.title = `Úprava produktu ${item.name}`;
        this.editProduct.id = new String(item.id);
      } else {
        this.dialog.title = "Přidání produktu";
      }
      this.showDialog = true;
    },
    closeProductDialog() {
      this.showDialog = false;
      this.editProduct.id = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  width: 20rem;
}

th {
  white-space: nowrap;
  cursor: pointer;

  & :hover {
    color: var(--v-accent-base);
  }
}

.data-table {
  ::v-deep .smallColumn {
    width: 3rem;
  }

  ::v-deep tr {
    cursor: v-bind(cursor);
  }
}
</style>
