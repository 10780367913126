<template>
  <div class="discount-table">
    <div class="d-flex justify-space-between">
      <div class="d-flex">
        <div class="search-input mr-4">
          <InputElement
            :rules="[rules.max250Char]"
            v-model="searchQuery"
            label="Vyhledat"
            append-icon="mdi-magnify"
            clearable
          />
        </div>
        <v-btn
          text
          depressed
          color="primary"
          @click="clearAllFilters"
          class="mb-4"
        >
          <v-icon left>mdi-filter-variant-remove</v-icon> Vyčistit všechny
          filtry</v-btn
        >
      </div>
      <v-btn
        v-if="showEdit"
        color="primary"
        depressed
        @click="showCreateDiscountDialog"
      >
        <v-icon left>mdi-plus</v-icon> Přidat slevu
      </v-btn>
    </div>
    <DataTable
      ref="productDataTable"
      v-if="discounts"
      :items="discounts"
      :headers="headers"
      :metadata="metadata"
      hide-default-header
      @updateFooterOptions="updateFooterOptions"
    >
      <template v-slot:header>
        <thead>
          <tr>
            <th v-for="(header, i) in headers" :key="i">
              <v-menu
                max-width="250"
                offset-y
                :close-on-content-click="false"
                :disabled="header.disableFilter"
              >
                <template v-slot:activator="{ on }">
                  <div class="d-flex" :class="header.class" v-on="on">
                    {{ header.text }}

                    <v-icon
                      v-if="header.text && !header.disableFilter"
                      small
                      :color="header.filterIsActive ? 'accent' : 'gray'"
                    >
                      mdi-filter-variant
                    </v-icon>
                  </div>
                </template>
                <FilterMenu
                  :value="header.filters"
                  :showSearch="header.showSearch"
                  @filterUpdate="filterUpdate($event, header)"
                  @orderBy="orderBy($event, header)"
                />
              </v-menu>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item.drag>
        <v-icon>mdi-drag</v-icon>
      </template>
      <template v-slot:item.value="{ item }">
        - {{ formatNumber(item.value) }}
      </template>

      <template v-slot:item.isHidden="{ item }">
        <v-btn icon color="primary" @click="changeHiddenStatus(item)">
          <v-icon>{{ item.isHidden ? "mdi-eye-off" : "mdi-eye" }} </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn icon color="primary" @click="showEditDiscountDialog(item)"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
      </template>
    </DataTable>

    <DialogWindow
      :showDialog="showDialog"
      :dialog="dialog"
      @closeDialog="closeDialog"
      @saveDialog="submit"
    >
      <v-form ref="form" @submit.prevent="onSubmit">
        <InputElement
          :rules="[rules.required]"
          label="Název"
          v-model="editDiscount.name"
        />
        <InputElement
          :rules="[rules.required, rules.number]"
          label="Sleva"
          v-model="editDiscount.value"
        />
      </v-form>
    </DialogWindow>
  </div>
</template>

<script>
import DataTable from "../elements/DataTable.vue";
import useApiCall from "../../use/apiCall";
import FilterMenu from "../elements/FilterMenu.vue";
import InputElement from "../elements/InputElement.vue";
import validation from "../../use/validation";
import useFormatNumber from "../../use/formatNumber";
import DialogWindow from "../elements/DialogWindow.vue";

import Sortable from "sortablejs";

export default {
  components: { DataTable, FilterMenu, InputElement, DialogWindow },
  props: {
    showEdit: {
      type: Boolean,
      default: false,
    },
    showAlsoHiddenItems: {
      type: Boolean,
      default: false,
    },
  },

  sortable: null,
  setup() {
    const { formatNumber } = useFormatNumber();
    const { getData, postData, putData } = useApiCall();

    return {
      getData,
      postData,
      putData,
      formatNumber,
    };
  },
  data() {
    return {
      rules: validation,
      searchQuery: null,
      skip: 0,
      take: 10,
      response: null,
      requestTimeOut: null,
      editDiscount: {},
      dialog: {},
      orderByParam: [],
      discounts: null,
      showDialog: false,
      discountsParams: {
        isHidden: false,
      },

      headers: [
        {
          text: "Název",
          value: "name",
          filters: "",
          filterIsActive: false,
        },
        {
          text: "Provize",
          value: "value",
          showSearch: false,
          filters: "",
          filterIsActive: false,
          align: "right",
          class: "justify-end",
        },
      ],
    };
  },

  watch: {
    searchQuery() {
      if (this.requestTimeOut) {
        clearTimeout(this.requestTimeOut);
      }
      this.requestTimeOut = setTimeout(() => {
        this.searchInTable();
      }, 600);
    },
  },

  computed: {
    cursor() {
      return this.showEdit ? "grab" : "default";
    },
  },

  async mounted() {
    if (this.showAlsoHiddenItems) {
      this.discountsParams = {
        isHidden: null,
      };
    }
    await this.fetchDiscounts();
    if (this.showEdit) {
      this.headers.unshift(
        {
          text: "",
          value: "drag",
          disableFilter: true,
          cellClass: "smallColumn",
          align: "right",
        },
        {
          text: "",
          value: "order",
          disableFilter: true,
          cellClass: "smallColumn",
          align: "right",
        }
      );
      this.headers.push(
        {
          text: "Viditelný",
          value: "isHidden",
          cellClass: "smallColumn",
          filters: "",
          filterIsActive: false,
          disableFilter: true,
          align: "right",
          class: "justify-end",
        },
        {
          text: "",
          value: "action",
          cellClass: "smallColumn",
          align: "right",
          filters: "",
          filterIsActive: false,
        }
      );

      const tbody = document.querySelector(".discount-table tbody");
      if (!tbody) return;
      this.sortable = new Sortable(tbody, {
        animation: 150,
        ghostClass: "primary--text",
        onEnd: async (e) => {
          if (e.oldIndex === undefined || e.newIndex === undefined) return;
          const dragged = this.discounts[e.oldIndex];
          const newOrder = e.newIndex + this.skip;
          const id = dragged.id;
          if (!id) return;
          if (dragged.order === newOrder + 1) return;
          this.editDiscount.id = id;

          await this.updateDiscount({
            discount: {
              ...dragged,
              order: newOrder + 1,
            },
          });
          await this.fetchDiscounts();
        },
      });
    }
  },

  beforeDestroy() {
    if (this.sortable) this.sortable.destroy();
  },
  methods: {
    async updateFooterOptions(event) {
      this.skip = event.page * event.itemsPerPage;
      this.take = event.itemsPerPage;
      await this.fetchDiscounts();
    },
    async fetchDiscounts() {
      this.discountsParams.orderBy = { ...this.orderByParam };
      const response = await this.getData(
        "discounts",
        this.skip,
        this.take,
        this.discountsParams
      );
      this.discounts = response.discounts;
      this.metadata = response.metadata;
    },

    async filterUpdate(event, header) {
      header.filters = event;
      header.filterIsActive = event;

      const paramKey = header.value;
      if (this.requestTimeOut) {
        clearTimeout(this.requestTimeOut);
      }

      this.discountsParams[`searchIn${paramKey}`] = event;
      this.requestTimeOut = setTimeout(() => {
        this.fetchDiscounts();
      }, 600);
    },

    async orderBy(event, header) {
      this.headers.forEach((el) => {
        el.filterIsActive = el.filters;
      });

      header.filterIsActive = true;

      const paramKey = header.value;
      this.orderByParam = event ? [`${paramKey}Asc`] : [`${paramKey}Desc`];
      await this.fetchDiscounts();
    },

    async clearAllFilters() {
      if (this.showAlsoHiddenItems) {
        this.discountsParams = {
          isHidden: null,
        };
      } else {
        this.discountsParams = {
          isHidden: false,
        };
      }
      this.orderByParam = [];
      this.headers.forEach((el) => {
        el.filters = null;
        el.filterIsActive = false;
      });
      this.skip = 0;
      this.take = 10;
      this.$refs.productDataTable.footerOptions.page = 0;
      this.$refs.productDataTable.footerOptions.itemsPerPage = 10;

      this.searchQuery = null;
      await this.fetchDiscounts();
    },
    async searchInTable() {
      this.discountsParams.searchQuery = this.searchQuery;
      await this.fetchDiscounts();
    },

    async submit() {
      const payload = {
        discount: { ...this.editDiscount },
      };

      if (this.editDiscount.id) {
        await this.updateDiscount(payload);
      } else {
        await this.createDiscount(payload);
      }

      if (this.response) {
        this.$store.commit("setFormMessagesTexts", [
          `${this.editDiscount.id ? "Sleva upravena" : "Sleva přidána"}`,
        ]);
        this.$store.commit("setFormMessagesType", "success");
        await this.fetchDiscounts();
        this.showDialog = false;
        this.response = null;
      }
    },

    async updateDiscount(payload) {
      this.response = await this.putData(
        `discounts/${this.editDiscount.id}`,
        payload
      );
    },

    async createDiscount(payload) {
      this.response = await this.postData(`discounts`, payload, null, true);
    },

    async changeHiddenStatus(item) {
      item.isHidden = !item.isHidden;
      await this.putData(
        `discounts/${item.id}/${item.isHidden ? "hide" : "restore"}`
      );
      await this.fetchDiscounts();
    },

    async showCreateDiscountDialog() {
      this.showDialog = true;
      this.dialog.title = `Přidat kategorii`;
    },

    showEditDiscountDialog(item) {
      this.showDialog = true;
      this.dialog.title = `Úprava slevy ${item.name}`;
      this.editDiscount = item;
    },

    closeDialog() {
      this.showDialog = false;
      this.editDiscount = {};
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  width: 20rem;
}
th {
  white-space: nowrap;
  cursor: pointer;
  & :hover {
    color: var(--v-accent-base);
  }
}

.data-table {
  ::v-deep .smallColumn {
    width: 3rem;
  }

  ::v-deep tr {
    cursor: v-bind(cursor);
  }
}
</style>
