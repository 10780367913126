<template>
  <div class="reset-password card">
    <SetNewPasswordForm :code="code" />
  </div>
</template>

<script>
import SetNewPasswordForm from "../components/form/SetNewPasswordForm.vue";

export default {
  components: { SetNewPasswordForm },
  name: "ResetPassword",
  data() {
    return {
      code: null,
    };
  },
  mounted() {
    this.code = this.$route.query.code;
  },
};
</script>

<style lang="scss" scoped>
.card {
    margin: auto;
    max-width: 30rem;
    text-align: center;
}
</style>