<template>
  <div class="dialog-window">
    <v-dialog v-model="showDialog" max-width="500" persistent>
      <div class="card">
        <div class="text-h5 mb-8">
          {{ dialog.title }}
        </div>
        <div>
          <slot />
        </div>
        <div v-if="!hideButtons" class="text-right mt-4">
          <v-btn text class="mr-2" @click="$emit('closeDialog')">
            Zavřít
          </v-btn>
          <v-btn depressed color="primary" @click="$emit('saveDialog')">
            Uložit
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    dialog: {
      type: Object,
      required: true,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
