<template>
  <div class="order-table">
    <v-alert v-model="hasInvalidOrder" type="error" text max-width="700">
      UPOZORNĚNÍ: Minimálně jedna z Vašich objednávek obsahuje položku či
      položky, které již nelze v aktuálním měsíci nabízet. Pro zobrazení
      nevalidních objednávek použijte, prosím, filtr "Validní" a zvolte možnost
      "ne". Tyto nevalidní objednávky je nutné převystavit a následně je smazat.
    </v-alert>
    <div class="d-flex justify-space-between mt-4 mb-4">
      <div class="d-flex align-center">
        <CalendarFilterButtons
          ref="calendarFilterButton"
          @dayButtonChange="dayButtonChange"
          class="mr-8"
        />
        <v-btn
          text
          depressed
          color="primary"
          :disabled="!orderList.length"
          :loading="loadingExportExcel"
          @click="handleDownload"
        >
          <v-icon left>mdi-table-arrow-right</v-icon>Exportovat</v-btn
        >
        <v-btn
          class="ml-4"
          text
          depressed
          color="primary"
          :disabled="!orderList.length"
          @click="handleDownloadAll"
          :loading="loadingExportExcelAll"
        >
          <v-icon left>mdi-table-arrow-right</v-icon>Exportovat vše
        </v-btn>
        <v-btn text depressed color="primary" @click="clearAllFilters">
          <v-icon left>mdi-filter-variant-remove</v-icon> Vyčistit filtry</v-btn
        >
      </div>

      <div class="text-right">
        <div>Suma provizí</div>
        <div class="text-h4">
          {{ formatNumber(ordersSum.netCommissionSum) }} Kč
        </div>
      </div>
    </div>
    <DataTable
      ref="orderDataTable"
      :headers="headers"
      :items="orderList"
      :item-class="rowClass"
      :metadata="ordersTableMetadata"
      @updateFooterOptions="updateFooterOptionsOrders"
      :footerOptions="footerOptions"
      hide-default-header
      :showExpand="userRole !== 'admin'"
    >
      <template v-slot:header>
        <thead>
          <tr>
            <th v-for="(header, i) in headers" :key="i">
              <v-menu
                offset-y
                :close-on-content-click="false"
                :disabled="header.disableFilter"
              >
                <template v-slot:activator="{ on }">
                  <div class="d-flex" v-on="on">
                    {{ header.text }}

                    <v-icon
                      v-if="header.text && !header.disableFilter"
                      small
                      :color="header.filterIsActive ? 'accent' : 'gray'"
                    >
                      mdi-filter-variant
                    </v-icon>
                  </div>
                </template>
                <FilterMenu
                  :value="header.filters"
                  :switchLabel="header.switchLabel"
                  :showSearch="header.showSearch"
                  :filterOptions="header.filterOptions"
                  :filterType="header.filterType"
                  :showDatepicker="header.showDatepicker"
                  :filterDates="filterDates"
                  :multiple="header.multiple"
                  :showOrderBtn="header.showOrderBtn"
                  :selectedItemText="selectedItemText"
                  @filterDatesUpdate="filterDatesUpdate($event, header)"
                  @filterSelectUpdate="filterSelectUpdate($event, header)"
                  @filterUpdate="filterUpdate($event, header)"
                  @orderBy="orderBy($event, header)"
                  @resetCalendar="resetCalendar(header)"
                />
              </v-menu>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item.seller.isLocked="{ item }">
        {{ item.seller.isLocked ? "Neaktivní" : "Aktivní" }}
      </template>
      <template v-slot:item.orderedAt="{ item }">
        {{ getShortDate(item.orderedAt) }}
      </template>
      <template v-slot:item.isTransfer="{ item }">
        {{ item.isTransfer ? "ano" : "ne" }}
      </template>
      <template v-slot:item.meetingType="{ item }">
        {{ localize(item) }}
      </template>
      <template v-slot:item.product.name="{ item }">
        <v-tooltip :disabled="item.product.name.length < 11" bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on" class="product-name">
              {{ getShortName(item.product.name) }}
            </div>
          </template>
          <span>
            {{ item.product.name }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.proDiscountPercentage="{ item }">
        {{ item.proDiscountPercentage && `${item.proDiscountPercentage} %` }}
      </template>

      <template v-slot:item.calculatedCurrentCommission="{ item }">
        {{ formatNumber(item.calculatedCurrentCommission) }}
      </template>

      <template v-slot:item.discounts="{ item }">
        <v-tooltip
          v-for="(discount, i) in item.discounts"
          :key="i"
          :disabled="discount.name.length < 11"
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-chip v-on="on" class="my-1" small>
              {{ getShortName(discount.name) }}
            </v-chip>
          </template>
          {{ discount.name }}
        </v-tooltip>
      </template>
      <template v-slot:item.calculatedProDiscount="{ item }">
        {{ formatNumber(item.calculatedProDiscount) }}
      </template>

      <template v-slot:item.calculatedNetCommission="{ item }">
        <td
          class="text-right"
          :class="item.calculatedNetCommission > 0 ? 'positive' : 'negative'"
        >
          {{ formatNumber(item.calculatedNetCommission) }} Kč
        </td>
      </template>

      <template v-slot:item.isValid="{ item }">
        <td class="text-right">{{ item.isValid ? "ano" : "ne" }}</td>
      </template>

      <template v-slot:item.delete="{ item }">
        <v-btn
          v-if="userCanEdit(item)"
          icon
          @click="showDeletDialog(item)"
          color="primary"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
        <v-btn
          icon
          v-if="userCanEdit(item) && item.isValid"
          @click="expand(!isExpanded)"
          color="primary"
        >
          <v-icon> mdi-pencil-outline</v-icon>
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" v-if="!item.isValid" icon color="primary">
              <v-icon color="red"> mdi-alert-outline </v-icon>
            </v-btn>
          </template>
          <span>
            Tato objednávka obsahuje minimálně jednu položku, kterou nelze v
            aktuálním měsíci nabízet. Převystavte, prosím, objednávku (vytvořte
            novou) a následně tuto nevalidní smažte.
          </span>
        </v-tooltip>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-4">
          <AddOrderForm
            ref="addOrderForm"
            editOrder
            :order="item"
            :categories="categories"
            :discounts="discounts"
            :o2Together="o2Together"
            :products="products"
            :calculatedOrder="getCaluclatedOrder(item)"
            @selectCategory="selectCategory($event)"
            @orderForm="orderForm"
            @editForm="editForm"
            @cancelChanges="cancelChanges"
            @discountTypeChange="discountTypeChange(item)"
            :editLoading="editLoading"
          />
        </td>
      </template>
    </DataTable>
    <v-dialog v-if="deleteItem" v-model="showDialog" max-width="500" persistent>
      <div class="white pa-8">
        <div class="text-h5 mb-4">Smazání objednávky</div>
        <div>
          Opravdu chcete smazat objednávku číslo {{ deleteItem.orderNumber }} s
          produktem {{ deleteItem.product.name }}?
        </div>
        <div class="mt-8 text-right">
          <v-btn text class="mr-4" @click="showDialog = false">Zavřít</v-btn>
          <v-btn depressed color="primary" @click="deleteOrder">Smazat</v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import DataTable from "../elements/DataTable.vue";
import useDateFormating from "../../use/dateFormating.js";
import AddOrderForm from "../form/AddOrderForm.vue";
import useApiCalls from "../../use/apiCall";
import FilterMenu from "../elements/FilterMenu.vue";
import CalendarFilterButtons from "../CalendarFilterButtons.vue";
import useFormatNumber from "../../use/formatNumber";

export default {
  components: { DataTable, AddOrderForm, FilterMenu, CalendarFilterButtons },
  setup() {
    const { formatNumber } = useFormatNumber();
    const { getShortDate, todayDate } = useDateFormating();
    const { getData, putData, deleteData, getRawData } = useApiCalls();
    return {
      getShortDate,
      getData,
      putData,
      deleteData,
      formatNumber,
      getRawData,
      todayDate,
    };
  },
  props: {
    categories: {
      type: Array,
      required: false,
    },
    o2Together: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      requestTimeOut: null,
      skipOrders: 0,
      takeOrders: 100,
      ordersTableMetadata: {},
      orderToBeUpdate: {},
      loadingExportExcel: false,
      loadingExportExcelAll: false,
      editLoading: false,
      selectedItemText: "",
      products: null,
      deleteItem: null,
      orderList: [],
      orderParams: {
        sellerIsLocked: false,
      },
      orderByParam: ["orderedAtDesc", "createdAtDesc"],
      showDialog: false,
      hasInvalidOrder: false,
      filterDates: [],
      discounts: [],
      footerOptions: {
        itemsPerPage: 100,
        page: 0,
      },
      ordersSum: {
        netCommissionSum: 0,
      },
      headers: [
        {
          text: "Období",
          value: "orderedAt",
          filters: "",
          showSearch: false,
          showDatepicker: true,
          filterIsActive: false,
        },
        {
          text: "Číslo objednávky",
          value: "orderNumber",
          filters: "",
          filterIsActive: false,
        },
        {
          text: "Číslo",
          value: "phoneNumber",
          filters: "",
          filterIsActive: false,
        },
        {
          text: "Skupina",
          value: "product.categoryName",
          filters: "",
          filterIsActive: false,
        },
        {
          text: "Produkt",
          value: "product.name",
          filters: "",
          filterIsActive: false,
        },
        {
          text: "Transfer",
          value: "isTransfer",
          filters: "",
          filterIsActive: false,
          switchLabel: "Transfer",
          showSearch: false,
          filterOptions: [
            { text: "Ano", value: true },
            { text: "Ne", value: false },
          ],
        },
        {
          text: "Typ schůzky",
          value: "meetingType",
          paramKey: "meetingTypes",
          showSearch: false,
          filterOptions: [
            { text: "DTB", value: "dtb" },
            { text: "OMNI", value: "omni" },
            { text: "Vlastní", value: "own" },
          ],
        },
        {
          text: "Ve spolu",
          value: "o2TogetherLevel",
          filters: "",
          filterIsActive: false,
          showSearch: false,
          filterOptions: [1, 2, 3, 4, 5, 6],
          align: "center",
        },
        {
          text: "Profi sleva %",
          value: "proDiscountPercentage",
          filters: "",
          filterIsActive: false,
          filterType: "Number",
          align: "center",
        },
        {
          text: "Slevy",
          value: "discounts",
          disableFilter: true,
        },
        {
          text: "Komentář",
          value: "comment",
          filters: "",
          filterIsActive: false,
        },
        {
          text: "Provize",
          value: "calculatedCurrentCommission",
          filters: "",
          showSearch: false,
          filterIsActive: false,
          align: "right",
        },
        {
          text: "Slevy",
          value: "calculatedDiscountsSum",
          filters: "",
          showSearch: false,
          filterIsActive: false,
          align: "right",
        },
        {
          text: "Profi sleva",
          value: "calculatedProDiscount",
          showSearch: false,
          filters: "",
          filterIsActive: false,
          align: "right",
        },
        {
          text: "LCO sleva",
          value: "calculatedLcoDiscount",
          showSearch: false,
          filters: "",
          filterIsActive: false,
          align: "right",
        },
        {
          text: "HWB sleva",
          value: "calculatedHwbDiscount",
          showSearch: false,
          filters: "",
          filterIsActive: false,
          align: "right",
        },
        {
          text: "Čistá provize",
          value: "calculatedNetCommission",
          showSearch: false,
          filters: "",
          align: "right",
          filterIsActive: false,
        },
        {
          text: "Validní",
          value: "isValid",
          showSearch: false,
          showOrderBtn: false,
          filterOptions: [
            { text: "Ano", value: true },
            { text: "Ne", value: false },
          ],
        },
      ],
      calculatedOrder: {
        calculatedCurrentCommission: 0,
        calculatedDiscountsSum: 0,
        calculatedProDiscount: 0,
        calculatedNetCommission: 0,
      },
    };
  },

  async mounted() {
    await this.fetchProducts(null);
    await this.fetchDiscounts();

    if (this.userRole !== "admin") {
      this.headers.push(
        { text: "", value: "delete" },
        {
          text: "",
          value: "data-table-expand",
          cellClass: "table-expand-icon",
        }
      );
    }

    if (this.userRole === "admin" || this.userRole === "manager") {
      this.headers.unshift(
        {
          text: "OZ",
          value: "seller.fullName",
          filters: "",
          filterIsActive: false,
          cellClass: "min-width-cell",
        },
        {
          text: "Status",
          value: "seller.isLocked",
          showSearch: false,
          filters: false,
          filterIsActive: true,
          cellClass: "min-width-cell",
          filterOptions: [
            { text: "Neaktivní", value: true },
            { text: "Aktivní", value: false },
          ],
        }
      );
    }

    if (this.userRole === "admin") {
      this.headers.unshift({
        text: "ASM",
        value: "superiorUser.fullName",
        filters: "",
        filterIsActive: false,
        cellClass: "min-width-cell",
      });
    }
  },

  computed: {
    userRole() {
      return this.$store.getters.getRole;
    },
  },

  methods: {
    async fetchProducts(categoryId) {
      ({ products: this.products } = await this.getData("products", 0, null, {
        isHidden: false,
        productCategoryId: categoryId,
      }));
    },

    async selectCategory(event) {
      await this.fetchProducts(event?.id);
    },

    async fetchOrders() {
      const userId = this.$store.getters.getUserId;
      this.orderParams.sellerId = this.userRole === "admin" ? null : userId;
      this.orderParams.orderBy = { ...this.orderByParam };

      const response = await this.getData(
        "orders",
        this.skipOrders,
        this.takeOrders,
        this.orderParams
      );
      this.orderList = response.orders;
      this.ordersSum = response.ordersSum;
      this.ordersTableMetadata = response.metadata;
      this.hasInvalidOrder = response.ordersSum.isAnyInvalid;
    },

    async fetchDiscounts() {
      ({ discounts: this.discounts } = await this.getData(
        "discounts",
        0,
        null,
        {
          isHidden: false,
        }
      ));
    },

    localize(item) {
      switch (item.meetingType) {
        case "dtb":
          return "DTB";
        case "omni":
          return "OMNI";
        case "own":
          return "Vlastní";
        default:
          return "-";
      }
    },

    discountTypeChange(item) {
      this.orderToBeUpdate.o2TogetherLevel = null;
      this.orderToBeUpdate.proDiscountPercentage = null;
      this.orderToBeUpdate.hwbDiscount = null;
      this.orderToBeUpdate.lcoDiscount = null;
      this.orderToBeUpdate.discountsIds = [];
      item.discounts = [];
    },

    async editForm() {
      this.editLoading = true;
      if (!this.orderToBeUpdate.discountsIds) {
        this.orderToBeUpdate.discountsIds = this.orderToBeUpdate.discounts.map(
          (e) => {
            return e.id;
          }
        );
      }

      if (!this.orderToBeUpdate.productId) {
        this.orderToBeUpdate.productId = this.orderToBeUpdate.product.id;
      }

      const result = await this.putData(`orders/${this.orderToBeUpdate.id}`, {
        order: this.orderToBeUpdate,
      });

      this.editLoading = false;

      if (!result) {
        return;
      }

      await this.fetchOrders();
      this.$refs.addOrderForm.syncForm();
      await this.$store.commit("setFormMessagesTexts", [
        `Objednávka číslo ${this.orderToBeUpdate.orderNumber} s produktem ${this.orderToBeUpdate.product.name}  byla upravena`,
      ]);
      await this.$store.commit("setFormMessagesType", "success");
      this.$refs.orderDataTable.$refs.dataTable._data.expansion = {};
    },

    async deleteOrder() {
      await this.deleteData("orders", this.deleteItem.id);
      await this.fetchOrders();
      this.showDialog = false;
    },

    orderForm(event) {
      this.orderToBeUpdate = event;
      if (this.orderToBeUpdate.discountsIds) {
        this.orderToBeUpdate.discountsIds = this.orderToBeUpdate.discounts.map(
          (e) => e.id
        );
      }
      if (this.orderToBeUpdate.productId) {
        this.orderToBeUpdate.productId = this.orderToBeUpdate.product.id;
      }
    },

    async cancelChanges() {
      await this.fetchOrders();
    },

    showDeletDialog(event) {
      this.showDialog = true;
      this.deleteItem = event;
    },
    getCaluclatedOrder(event) {
      const calculatedOrder = {};
      calculatedOrder.calculatedCurrentCommission =
        event.calculatedCurrentCommission;
      calculatedOrder.calculatedDiscountsSum = event.calculatedDiscountsSum;
      calculatedOrder.calculatedProDiscount = event.calculatedProDiscount;
      calculatedOrder.calculatedNetCommission = event.calculatedNetCommission;
      return calculatedOrder;
    },
    transferHeaderValueToParamKey(value) {
      switch (value) {
        case "product.categoryName":
          return "ProductCategoryName";
        case "product.name":
          return "ProductName";
        case "seller.fullName":
          return "SellerFullName";
        case "superiorUser.fullName":
          return "SuperiorUserFullName";
        case "seller.isLocked":
          return "sellerIsLocked";
        default:
          return value;
      }
    },

    async filterSelectUpdate(event, header) {
      const paramKey =
        header.paramKey || this.transferHeaderValueToParamKey(header.value);

      if (event) {
        this.orderParams[paramKey] = event;
      } else {
        delete this.orderParams[paramKey];
      }

      header.filters = event;
      header.filterIsActive = event !== null;
      await this.fetchOrders();
    },

    async filterUpdate(event, header) {
      header.filters = event;
      header.filterIsActive = event;

      const paramKey = this.transferHeaderValueToParamKey(header.value);
      if (this.requestTimeOut) {
        clearTimeout(this.requestTimeOut);
      }
      if (paramKey === "proDiscountPercentage") {
        this.orderParams[paramKey] = event;
        this.requestTimeOut = setTimeout(() => {
          this.fetchOrders();
        }, 600);
        return;
      }
      this.orderParams[`searchIn${paramKey}`] = event;
      this.requestTimeOut = setTimeout(() => {
        this.fetchOrders();
      }, 600);
    },

    async orderBy(event, header) {
      this.headers.forEach((el) => {
        el.filterIsActive = el.filters;
      });

      header.filterIsActive = true;

      const paramKey = this.transferHeaderValueToParamKey(header.value);
      this.orderByParam = event
        ? [`${paramKey}Asc`, "createdAtDesc"]
        : [`${paramKey}Desc`, "createdAtDesc"];
      await this.fetchOrders();
    },

    async switchChange(event) {
      this.orderParams.isTransfer = event;
      await this.fetchOrders();
    },

    async filterDatesUpdate(event, header) {
      this.filterDates = event;
      if (this.filterDates[0] > this.filterDates[1]) {
        let first = this.filterDates[1];
        this.filterDates[1] = this.filterDates[0];
        this.filterDates[0] = first;
      }
      this.orderParams.orderedAtFrom = this.filterDates[0];
      this.orderParams.orderedAtTo = this.filterDates[1];
      header.filterIsActive = this.filterDates.length;
      await this.fetchOrders();
    },
    async resetCalendar(header) {
      this.filterDates = [];
      this.orderParams.orderedAtFrom = this.filterDates[0];
      this.orderParams.orderedAtTo = this.filterDates[1];
      header.filterIsActive = false;
      await this.fetchOrders();
    },
    async dayButtonChange(event) {
      this.orderParams.orderedAtFrom = event.from;
      this.orderParams.orderedAtTo = event.to;
      await this.fetchOrders();
    },

    async exportExcel() {
      const excel = await this.getRawData(
        "orders/export",
        this.skipOrders,
        this.takeOrders,
        this.orderParams
      );

      return excel;
    },

    async exportExcelAll() {
      const excel = await this.getRawData(
        "orders/export",
        0,
        null,
        this.orderParams
      );

      return excel;
    },

    async handleDownload() {
      try {
        this.loadingExportExcel = true;
        const response = await this.exportExcel();
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        const contentDisposition = response.headers["content-disposition"];
        const fileName = contentDisposition.match(/"([^']+)"/)[1];

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingExportExcel = false;
      }
    },

    async handleDownloadAll() {
      try {
        this.loadingExportExcelAll = true;
        const response = await this.exportExcelAll();
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        const contentDisposition = response.headers["content-disposition"];
        const fileName = contentDisposition.match(/"([^']+)"/)[1];

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingExportExcelAll = false;
      }
    },

    async clearAllFilters() {
      this.orderParams = {};
      this.orderByParam = ["orderedAtDesc", "createdAtDesc"];
      this.orderParams.sellerIsLocked = false;
      this.headers.forEach((el) => {
        el.filters = null;
        el.filterIsActive = false;
      });

      if (this.userRole === "admin") {
        const userIsLockedFilter = this.headers.find((header) => {
          return header.value === "seller.isLocked";
        });
        userIsLockedFilter.filters = false;
        userIsLockedFilter.filterIsActive = true;
      }
      this.orderParams.orderedAtFrom = this.todayDate();
      this.orderParams.orderedAtTo = this.todayDate();
      this.$refs.calendarFilterButton.dayFilter = 0;
      this.skipOrders = 0;
      this.takeOrders = 100;
      this.$refs.orderDataTable.footerOptions.page = 0;
      this.$refs.orderDataTable.footerOptions.itemsPerPage = 100;
      await this.fetchOrders();
    },

    async updateFooterOptionsOrders(event) {
      this.skipOrders = event.page * event.itemsPerPage;
      this.takeOrders = event.itemsPerPage;
      console.log("updateFooter");
      await this.fetchOrders();
    },

    userCanEdit(item) {
      const userId = this.$store.getters.getUserId;

      if (item.seller.id === userId) {
        return item.isEditable;
      } else {
        return false;
      }
    },

    getShortName(name) {
      return name.length > 11 ? name.slice(0, 11 - 1) + "..." : name;
    },

    rowClass(item) {
      return item.isValid ? "" : "invalid-row";
    },
  },
};
</script>

<style lang="scss" scoped>
.order-table {
  th {
    white-space: nowrap;
    cursor: pointer;

    & :hover {
      color: var(--v-accent-base);
    }
  }

  ::v-deep .positive {
    background: rgb(177, 235, 177);
  }

  ::v-deep .negative {
    background: rgb(253, 137, 166);
  }

  ::v-deep .invalid-row {
    background: rgb(255, 220, 220);

    .positive {
      background: rgb(255, 220, 220);
    }

    &:hover {
      background: rgb(255, 220, 220) !important;
    }
  }

  .product-name {
    white-space: nowrap;
  }

  ::v-deep .min-width-cell {
    width: 0;
  }
}
</style>
