<template>
  <div class="product-detail">
    <v-form v-if="product" @submit.prevent="onSubmit" ref="form">
      <input-element
        :label="'Název'"
        v-model="product.name"
        :rules="[rules.required]"
      />
      <select-input-element
        ref="categorySelect"
        :rules="[rules.required]"
        :label="'Kategorie'"
        v-model="product.productCategory.id"
        :items="categories"
        :value="search"
        item-text="name"
        item-value="id"
      >
        <template v-slot:prepend-item>
          <div class="text-right">
            <v-btn
              text
              color="primary"
              class="mb-2 add-category-btn"
              @click="addCategory"
            >
              Přidat novou kategorii
            </v-btn>
          </div>
        </template>
      </select-input-element>
      <input-element
        :rules="[rules.required, rules.number]"
        :label="'Provize'"
        v-model="product.commission"
      />
      <v-switch
        inset
        label="Počítat jako službu"
        class="mt-0"
        v-model="product.isService"
        @change="switchChange"
      />
      <v-switch
        inset
        label="Ve spolu"
        class="mt-0"
        v-model="product.allowO2Together"
        @change="switchChange"
      />
      <div v-if="product.allowO2Together">
        <input-element
          :rules="product.allowO2Together ? [rules.required, rules.number] : []"
          :label="'Ve spolu 1'"
          v-model="product.o2TogetherLevel1Commission"
        />
        <input-element
          :rules="product.allowO2Together ? [rules.required, rules.number] : []"
          :label="'Ve spolu 2'"
          v-model="product.o2TogetherLevel2Commission"
        />
        <input-element
          :rules="product.allowO2Together ? [rules.required, rules.number] : []"
          :label="'Ve spolu 3'"
          v-model="product.o2TogetherLevel3Commission"
        />
        <input-element
          :rules="product.allowO2Together ? [rules.required, rules.number] : []"
          :label="'Ve spolu 4'"
          v-model="product.o2TogetherLevel4Commission"
        />
        <input-element
          :label="'Ve spolu 5'"
          :rules="product.allowO2Together ? [rules.required, rules.number] : []"
          v-model="product.o2TogetherLevel5Commission"
        />
        <input-element
          :rules="product.allowO2Together ? [rules.required, rules.number] : []"
          :label="'Ve spolu 6'"
          v-model="product.o2TogetherLevel6Commission"
        />
      </div>
      <div class="text-right">
        <v-btn depressed text class="mr-4" @click="cancelChanges">
          Zrušit
        </v-btn>
        <v-btn color="primary" depressed type="submit"> Uložit </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import InputElement from "../components/elements/InputElement.vue";
import SelectInputElement from "../components/elements/SelectInputElement.vue";
import useApiCall from "../use/apiCall";
import validation from "../use/validation";

export default {
  components: { InputElement, SelectInputElement },
  setup() {
    const { getData, putData, postData } = useApiCall();
    return {
      getData,
      putData,
      postData,
    };
  },

  data() {
    return {
      defaultProductObject: {
        name: null,
        commission: null,
        productCategory: {
          id: null,
        },
        allowO2Together: false,
        o2TogetherLevel1Commission: null,
        o2TogetherLevel2Commission: null,
        o2TogetherLevel3Commission: null,
        o2TogetherLevel4Commission: null,
        o2TogetherLevel5Commission: null,
        o2TogetherLevel6Commission: null,
        isService: true,
      },
      response: null,
      product: {
        name: null,
        commission: null,
        productCategory: {
          id: null,
        },
        isService: true,
        allowO2Together: false,
        o2TogetherLevel1Commission: null,
        o2TogetherLevel2Commission: null,
        o2TogetherLevel3Commission: null,
        o2TogetherLevel4Commission: null,
        o2TogetherLevel5Commission: null,
        o2TogetherLevel6Commission: null,
      },
      categories: null,
      search: null,
      rules: validation,
    };
  },

  props: {
    productId: {
      type: String,
      required: false,
    },
  },

  watch: {
    productId() {
      if (this.productId) {
        this.fetchProductDetail();
      }
    },
  },

  async mounted() {
    if (this.productId) {
      await this.fetchProductDetail();
    }
    ({ categories: this.categories } = await this.$store.dispatch(
      "fetchCategories"
    ));
  },

  methods: {
    async fetchProductDetail() {
      ({ product: this.product } = await this.getData(
        `products/${this.productId}`
      ));
    },

    async onSubmit() {
      this.product.productCategoryId = this.product.productCategory.id;

      this.productId ? await this.editProduct() : await this.createProduct();

      if (this.response) {
        this.$store.commit("setFormMessagesTexts", [
          this.productId ? `Produkt upraven` : `Produkt přidán`,
        ]);
        this.$store.commit("setFormMessagesType", "success");
        this.$emit("productChanged");
        this.product = this.defaultProductObject;
        this.$refs.form.resetValidation();
        this.response = null;
      }
    },

    async editProduct() {
      this.response = await this.putData(
        `products/${this.productId}`,
        {
          product: this.product,
        },
        true
      );
    },

    async createProduct() {
      this.response = await this.postData(
        "products",
        {
          product: this.product,
        },
        null,
        true
      );
    },

    async cancelChanges() {
      this.$emit("closeDialog");
      this.$refs.form.resetValidation();
      
      this.product = this.defaultProductObject;
    },

    async addCategory() {
      const inputValue = this.$refs.categorySelect.$el.__vue__.lazySearch;
      const payload = {
        category: {
          name: inputValue,
        },
      };
      const response = await this.postData("product-categories", payload);
      if (response) {
        this.$store.commit("setFormMessagesTexts", ["Nová katergorie přidána"]);
        this.$store.commit("setFormMessagesType", "success");
        ({ categories: this.categories } = await this.$store.dispatch(
          "fetchCategories"
        ));
      }
    },

    switchChange() {
      if (!this.product.allowO2Together) {
        this.product.o2TogetherLevel1Commission = null;
        this.product.o2TogetherLevel2Commission = null;
        this.product.o2TogetherLevel3Commission = null;
        this.product.o2TogetherLevel4Commission = null;
        this.product.o2TogetherLevel5Commission = null;
        this.product.o2TogetherLevel6Commission = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-category-btn {
  width: 100%;
}
</style>
