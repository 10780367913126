<template>
  <v-form ref="form" @submit.prevent="onSubmit">
    <PasswordInputElement
      :rules="[rules.required]"
      v-model="setNewPassword.currentPassword"
      label="Současné heslo"
    />
    <PasswordInputElement
      :rules="[rules.required]"
      v-model="setNewPassword.newPassword"
      label="Nové heslo"
      @input="checkIfPasswordMatchAgain"
    />
    <PasswordInputElement
      :rules="[rules.required]"
      :error-messages="passwordDontMatch"
      v-model="newPasswordAgain"
      label="Nové heslo znovu"
      @input="checkIfPasswordMatch"
    />
    <div class="text-center">
      <v-btn type="submit" depressed color="primary">Změnit heslo</v-btn>
    </div>
  </v-form>
</template>

<script>
import PasswordInputElement from "../elements/PasswordInputElement.vue";
import useApiCall from "../../use/apiCall";
import validation from "../../use/validation";

export default {
  components: { PasswordInputElement },
  setup() {
    const { putData } = useApiCall();
    return {
      putData,
    };
  },
  data() {
    return {
      setNewPassword: {
        currentPassword: null,
        newPassword: null,
      },
      newPasswordAgain: null,
      rules: validation,
      passwordDontMatch: null,
    };
  },
  methods: {
    async onSubmit() {
      if (this.passwordDontMatch) {
        return;
      }
      const result = await this.putData(
        "auth/password",
        this.setNewPassword,
        true
      );

      if (result) {
        await this.$store.commit("setToken", result.auth.accessToken);
        await this.$store.commit(
          "setTokenExpiration",
          result.auth.accessTokenExpiresAt
        );
        await this.$store.commit("setFormMessagesTexts", [
          `Heslo bylo změněno`,
        ]);
        await this.$store.commit("setFormMessagesType", "success");
      }
    },

    checkIfPasswordMatch() {
      this.newPasswordAgain === this.setNewPassword.newPassword
        ? (this.passwordDontMatch = null)
        : (this.passwordDontMatch = "Hesla nejsou shodná");
    },

    checkIfPasswordMatchAgain() {
      if (!this.newPasswordAgain) {
        return;
      }
      this.checkIfPasswordMatch();
    },
  },
};
</script>

<style>
</style>