<template>
  <v-form @submit.prevent="onSubmit" ref="form">
    <PasswordInputElement
      :rules="[rules.required]"
      label="Heslo"
      v-model="payload.password"
      type="password"
      @blur="checkIfPasswordMatchAgain"
    />
    <PasswordInputElement
      v-model="passwordAgain"
      :error-messages="passwordMatch"
      :rules="[rules.required]"
      label="Heslo znovu"
      type="password"
      @blur="checkIfPasswordMatch"
    />
    <div class="text-center">
      <v-btn type="submit" color="primary" depressed
        >Dokončit registraci
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import PasswordInputElement from "../elements/PasswordInputElement.vue";
import useApiCall from "../../use/apiCall";
import validation from "../../use/validation";

export default {
  name: "VerifyEmailForm",
  components: { PasswordInputElement },
  setup() {
    const { postData } = useApiCall();
    return { postData };
  },
  data() {
    return {
      passwordMatch: null,
      passwordAgain: null,
      rules: validation,
      payload: {
        password: null,
        code: this.$route.query.code,
      },
    };
  },

  methods: {
    async onSubmit() {
      const user = await this.postData(
        "/users/email-verification/submit-code",
        this.payload,
        null,
        true
      );

      if (!user) {
        return;
      }
      this.$store.commit("setUserInfo", user);
      this.$router.push({ name: "Home" });
    },

    checkIfPasswordMatch() {
      this.passwordAgain === this.payload.password
        ? (this.passwordMatch = null)
        : (this.passwordMatch = "Hesla nejsou shodná");
    },
    checkIfPasswordMatchAgain() {
      if (!this.passwordAgain) {
        return;
      }
      this.checkIfPasswordMatch();
    },
  },
};
</script>

<style></style>
