<template>
  <div class="users container">
    <UserTable class="mb-12" />
    <UserActivityTable v-if="userRole === 'admin'" />
  </div>
</template>

<script>
import UserActivityTable from "../components/tables/UserActivityTable.vue";
import UserTable from "../components/tables/UserTable.vue";

export default {
  components: { UserTable, UserActivityTable },
  name: "Users",
  computed: {
    userRole() {
      return this.$store.getters.getRole;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
