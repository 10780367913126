import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Users from "../views/Users.vue";
import Meetings from "../views/Meetings.vue";
import VerifyEmail from "../views/VerifyEmail.vue";
import Orders from "../views/Orders.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import ChangePassword from "../views/ChangePassword.vue";
import PageNotFound from "../views/PageNotFound.vue";
import EmailConfirmation from "../views/EmailConfirmation.vue";
import Tariffs from "../views/Tariffs.vue";
import Products from "../views/Products.vue";
import ProductDetail from "../views/ProductDetail.vue";
import store from "../store";
import checkTokenExpiration from "../use/checkTokenExpiration";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: "authorized",
  },
  {
    path: "/prihlaseni",
    name: "Login",
    component: Login,
    meta: "unAuthorized",
  },
  {
    path: "/potvrzeni-emailu",
    name: "Login",
    component: EmailConfirmation,
    meta: "unAuthorized",
  },
  {
    path: "/uzivatele",
    name: "Users",
    component: Users,
    meta: "authorized",
  },
  {
    path: "/verify-email",
    name: "VerifyEmail",
    component: VerifyEmail,
    meta: "unAuthorized",
  },
  {
    path: "/schuzky",
    name: "Meetings",
    component: Meetings,
    meta: "authorized",
  },
  {
    path: "/objednavky",
    name: "Orders",
    component: Orders,
    meta: "authorized",
  },
  {
    path: "/zapomenute-heslo",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: "unAuthorized",
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: "unAuthorized",
  },
  {
    path: "/zmena-hesla",
    name: "ChangePassword",
    component: ChangePassword,
    meta: "authorized",
  },
  {
    path: "/sazebnik",
    name: "Tariffs",
    component: Tariffs,
    meta: "authorized",
  },
  {
    path: "/produkty",
    name: "Products",
    component: Products,
    meta: "authorized",
  },
  {
    path: "/produkty/:id",
    name: "ProductDetail",
    component: ProductDetail,
    meta: "authorized",
  },
  { path: "*", name: "PageNotFound", component: PageNotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const authorizedUser = checkTokenExpiration();
  const userRole = store.getters.getRole;

  if (authorizedUser && to.meta === "unAuthorized") {
    next({ name: "Home" });
  } else if (!authorizedUser) {
    to.meta === "authorized" ? next({ name: "Login" }) : next();
  } else {
    next();
  }

  if (to.name === "Home" && userRole === "admin") {
    next({ name: "Users" });
  }

  if(userRole === "assistant") {
    next({name: "Orders"})
  }
});

export default router;
