<template>
  <div class="meetings container">
    <div class="text-h6 grey--text mb-4">SCHŮZKY</div>
    <v-row>
      <v-col cols="7">
        <calendar
          :date="date"
          :events="events"
          @clickOnDate="clickOnDate"
          @pickerDateUpdate="pickerDateUpdate"
        />
      </v-col>
      <v-spacer />
      <v-col cols="5">
        <MeetingForm
          class="card"
          :date="date"
          :plannedMeetings="plannedMeetings"
          :realizedMeetings="realizedMeetings"
          :totalPlanned="totalPlanned"
          :totalRealized="totalRealized"
          :editForm="editForm"
          :disableForm="disableForm"
          @plannedMeetingUpdate="plannedMeetingUpdate"
          @realizedMeetingUpdate="realizedMeetingUpdate"
          @meetingFormSubmit="meetingFormSubmit"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Calendar from "../components/elements/Calendar.vue";
import MeetingForm from "../components/form/MeetingForm.vue";
import useApiCall from "../use/apiCall";

export default {
  components: { Calendar, MeetingForm },
  setup() {
    const { getData, postData, putData } = useApiCall();
    return { getData, postData, putData };
  },
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      editForm: false,
      disableForm: false,
      events: [],
      detailMeeting: null,
      meetingsFromCalendar: null,
      startOfCurrentMonth: null,
      startOfNextMonth: null,
      plannedMeetings: [
        {
          label: "OMNI",
          key: "omniPlanned",
          value: 0,
        },
        {
          label: "DTB",
          key: "dtbPlanned",
          value: 0,
        },
        {
          label: "VLASTNÍ",
          key: "ownPlanned",
          value: 0,
        },
      ],
      realizedMeetings: [
        {
          label: "OMNI",
          key: "omniRealized",
          value: 0,
        },
        {
          label: "DTB",
          key: "dtbRealized",
          value: 0,
        },
        {
          label: "VLASTNÍ",
          key: "ownRealized",
          value: 0,
        },
      ],
    };
  },
  async mounted() {
    await this.fetchPlannedMeetings();
    await this.fetchDetailMeeting();
  },

  computed: {
    totalPlanned() {
      return this.sumArrayNumbers(this.plannedMeetings);
    },
    totalRealized() {
      return this.sumArrayNumbers(this.realizedMeetings);
    },
  },

  methods: {
    async pickerDateUpdate(event) {
      const date = new Date(event);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");

      // Create a new Date object for the next month
      let nextMonthDate = new Date(year, date.getMonth() + 1);

      // Calculate the year and month of the next month
      const nextYear = nextMonthDate.getFullYear();
      const nextMonth = (nextMonthDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");

      this.startOfCurrentMonth = `${year}-${month}-01`;
      this.startOfNextMonth = `${nextYear}-${nextMonth}-01`;

      await this.fetchPlannedMeetings();
    },
    async fetchPlannedMeetings() {
      const userId = this.$store.getters.getUserId;
      ({ meetings: this.meetingsFromCalendar } = await this.getData(
        "/meetings",
        0,
        100,
        {
          userId: userId,
          from: this.startOfCurrentMonth,
          to: this.startOfNextMonth,
        }
      ));

      this.events = this.meetingsFromCalendar.map(
        (meeting) => meeting.plannedOn
      );
    },
    async fetchDetailMeeting() {
      const getPlannedMeetingFromCalender = this.meetingsFromCalendar.find(
        (el) => el.plannedOn === this.date
      );

      if (!getPlannedMeetingFromCalender) {
        this.resetForm();
        this.detailMeeting = null;
        return;
      }
      ({ meeting: this.detailMeeting } = await this.getData(
        `/meetings/${getPlannedMeetingFromCalender?.id}`
      ));
      this.editForm = true;
      this.getDetailMeetingValues(this.plannedMeetings);
      this.getDetailMeetingValues(this.realizedMeetings);
    },
    async clickOnDate(value) {
      this.date = value;
      await this.fetchDetailMeeting();
      this.checkPastDate(this.date);
    },

    async meetingFormSubmit() {
      const plannedMeetingsObject = {};
      const realizedMeetingsObject = {};

      this.createKeyValueObjectFromArray(
        this.plannedMeetings,
        plannedMeetingsObject
      );
      this.createKeyValueObjectFromArray(
        this.realizedMeetings,
        realizedMeetingsObject
      );
      const postMeetings = {
        ...plannedMeetingsObject,
        ...realizedMeetingsObject,
        plannedOn: this.date,
      };
      const putMeetings = {
        ...plannedMeetingsObject,
        ...realizedMeetingsObject,
      };

      const response = this.editForm
        ? await this.putData(`/meetings/${this.detailMeeting.id}`, {
            meeting: putMeetings,
          })
        : await this.postData("/meetings", { meeting: postMeetings });
      if (!response) {
        return;
      }
      await this.fetchPlannedMeetings();
      await this.$store.commit("setFormMessagesTexts", [
        this.editForm
          ? "Schůzky byly úspěšně upraveny"
          : "Schůzky byly úspěšně uloženy",
      ]);
      await this.$store.commit("setFormMessagesType", "success");
      await this.fetchDetailMeeting();
    },
    getDetailMeetingValues(meetings) {
      meetings.forEach((el) => {
        const key = el.key;

        el.value = this.detailMeeting[key];
      });
    },
    plannedMeetingUpdate(event) {
      this.plannedMeetings[event.index].value = Number(event.value);
    },
    realizedMeetingUpdate(event) {
      this.realizedMeetings[event.index].value = Number(event.value);
    },
    resetForm() {
      this.plannedMeetings.forEach((el) => (el.value = 0));
      this.realizedMeetings.forEach((el) => (el.value = 0));
    },
    sumArrayNumbers(meetings) {
      const arrayValues = meetings.map((meeting) => Number(meeting.value));
      return arrayValues.reduce((a, b) => a + b, 0);
    },
    createKeyValueObjectFromArray(array, object) {
      array.forEach((el) => {
        return (object[el.key] = el.value);
      });
    },
    async checkPastDate(date) {
      const selectedMonth = new Date(date).getMonth();
      const currentMonth = new Date().getMonth();
      if (selectedMonth < currentMonth) {
        this.editForm = true;
        this.disableForm = true;
        return;
      }

      this.disableForm = false;

      if (this.detailMeeting) {
        this.editForm = true;
      } else {
        this.editForm = false;
      }
    },
  },
};
</script>

<style></style>
