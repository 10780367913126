<template>
  <v-text-field
    dense
    v-bind="$attrs"
    :value="value"
    @input="(v) => $emit('input', v)"
    outlined
    flat
    :label="label"
    :type="show ? 'text' : 'password'"
    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
    @click:append="show = !show"
    @blur="$emit('blur')"
  ></v-text-field>
</template>

<script>
export default {
  name: "InputElement",
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: String,
    },
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style></style>
