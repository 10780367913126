import store from '../store';
import router from '../router';

export default function (error) {
    const unAuthorizedRequest = error.response.status === 403
    const erorArray = error.response.data.errors;
    const errorTexts = erorArray.map((error) => error.message);

    if (unAuthorizedRequest) {
        store.commit("setUserInfo", null);
        router.push({ name: 'Login' });
        store.commit(
            'setLoginAgainText',
            'Byl jste automaticky odhlášen prosím přihlašte se znovu'
        );
    }
    store.commit('setFormMessagesTexts', errorTexts);
    store.commit('setFormMessagesType', 'error');
}