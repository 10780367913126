<template>
  <div class="filter-menu">
    <v-btn v-if="showOrderBtn" color="accent" depressed @click="orderBy">
      {{ `Seřadit ${asc ? "vzestupně" : "sestupně"}` }}
      <v-icon right> {{ `mdi-arrow-${asc ? "up" : "down"}` }}</v-icon></v-btn
    >
    <v-select
      v-if="filterOptions"
      clearable
      :value="value"
      class="pt-0 mt-6"
      label="Vyhledat"
      :items="filterOptions"
      :multiple="multiple"
      @input="$emit('filterSelectUpdate', $event)"
    >
    </v-select>
    <div v-if="showDatepicker">
      <v-date-picker
        class="mt-6"
        :value="filterDates"
        locale="cs"
        :selected-items-text="selectedItemText"
        @input="$emit('filterDatesUpdate', $event)"
        range
      ></v-date-picker>
      <div class="text-center mt-4">
        <v-btn text color="primary" @click="$emit('resetCalendar')"
          >Resetovat datum</v-btn
        >
      </div>
    </div>
    <InputElement
      v-if="showSearch"
      :type="filterType"
      min="0"
      max="100"
      class="mt-6"
      :value="value"
      @input="$emit('filterUpdate', $event)"
      label="Vyhledat"
      :outlined="false"
    />
  </div>
</template>

<script>
import InputElement from "./InputElement.vue";
export default {
  components: { InputElement },
  props: {
    showDatepicker: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    filterDates: {
      type: Array,
      defult: null,
    },
    selectedItemText: {
      type: String,
      default: "",
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    switchLabel: {
      type: String,
      default: "",
    },
    filterOptions: {
      type: Array,
      default: null,
    },
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    filterType: {
      type: String,
      default: "String",
    },
    showOrderBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      asc: true,
    };
  },
  methods: {
    orderBy() {
      this.asc = !this.asc;
      this.$emit("orderBy", this.asc);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-menu {
  background: white;
  padding: 1rem;
}
</style>
