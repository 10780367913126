<template>
  <div class="calendar-filter-buttons">
    <v-btn-toggle
      mandatory
      v-model="dayFilter"
      color="primary"
      @change="dayButtonChange"
    >
      <v-btn v-for="(buttonDay, i) in buttonDays" :key="i" color="white">
        {{ buttonDay.name }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import useDateFormating from "../use/dateFormating";

export default {
  name: "CalendarFilterButtons",
  setup() {
    const {
      todayDate,
      yesterdayDate,
      beginOfThisWeek,
      endOfThisWeek,
      beginOfLastWeek,
      endOfLastWeek,
      beginOfThisMonth,
      endOfThisMonth,
      beginOfLastMonth,
      endOfLastMonth,
    } = useDateFormating();
    return {
      todayDate,
      yesterdayDate,
      beginOfThisWeek,
      endOfThisWeek,
      beginOfLastWeek,
      endOfLastWeek,
      beginOfThisMonth,
      endOfThisMonth,
      beginOfLastMonth,
      endOfLastMonth,
    };
  },
  data() {
    return {
      dayFilter: 0,
      buttonDays: [
        {
          name: "Dnes",
          value: "today",
        },
        {
          name: "Včera",
          value: "yesterday",
        },
        {
          name: "Tento týden",
          value: "thisWeek",
        },
        {
          name: "Minulý týden",
          value: "lastWeek",
        },
        {
          name: "Tento měsíc",
          value: "thisMonth",
        },
        {
          name: "Minulý měsíc",
          value: "lastMonth",
        },
      ],
    };
  },

  mounted() {
    this.dayButtonChange();
  },
  methods: {
    dayButtonChange() {
      const fromToDate = this.getFilterDate();
      this.$emit("dayButtonChange", fromToDate);
    },
    getFilterDate() {
      switch (this.dayFilter) {
        case 0:
          return { from: this.todayDate(), to: this.todayDate() };
        case 1:
          return { from: this.yesterdayDate(), to: this.yesterdayDate() };
        case 2:
          return { from: this.beginOfThisWeek(), to: this.endOfThisWeek() };
        case 3:
          return { from: this.beginOfLastWeek(), to: this.endOfLastWeek() };
        case 4:
          return { from: this.beginOfThisMonth(), to: this.endOfThisMonth() };
        case 5:
          return { from: this.beginOfLastMonth(), to: this.endOfLastMonth() };
        default:
          return { from: this.todayDate(), to: this.todayDate() };
      }
    },
  },
};
</script>

<style>
</style>