<template>
  <div class="meetings-statistics">
    <div class="d-flex align-center mb-4">
      <CalendarFilterButtons
        ref="calendarFilterButton"
        @dayButtonChange="dayButtonChange"
      />

      <InputDatePicker
        ref="inputDatePicker"
        class="ml-4"
        @saveDates="saveDates"
      />

      <v-btn
        class="ml-4"
        text
        depressed
        color="primary"
        :disabled="!statistics.length"
        :loading="loadingExportExcel"
        @click="handleDownload"
      >
        <v-icon left>mdi-table-arrow-right</v-icon>Exportovat
      </v-btn>

      <v-btn
        class="ml-4"
        text
        depressed
        color="primary"
        :disabled="!statistics.length"
        :loading="loadingExportExcelAll"
        @click="handleDownloadAll"
      >
        <v-icon left>mdi-table-arrow-right</v-icon>Exportovat vše
      </v-btn>

      <v-btn
        text
        depressed
        color="primary"
        class="ml-4"
        @click="clearAllFilters"
      >
        <v-icon left>mdi-filter-variant-remove</v-icon> Vyčistit filtry
      </v-btn>
    </div>

    <DataTable
      v-if="totalSums && statistics"
      ref="meetingsStatisticTable"
      :headers="headers"
      :items="statistics"
      :footerOptions="footerOptions"
      hide-default-header
      :metadata="meetingsTableMetadata"
      @updateFooterOptions="updateFooterOptionsMeetings"
    >
      <template v-slot:header>
        <thead>
          <tr>
            <th
              v-for="(headersTitle, i) in headersTitles"
              :key="i"
              :colspan="headersTitle.colspan"
              class="text-center"
              :class="i + 1 < headersTitles.length && 'table-border-right'"
            >
              {{ headersTitle.text }}
            </th>
          </tr>
          <tr>
            <th v-for="(header, i) in headers" :key="i" :class="header.class">
              <v-menu
                offset-y
                :close-on-content-click="false"
                :disabled="header.disableFilter"
              >
                <template v-slot:activator="{ on }">
                  <div class="d-flex" v-on="on">
                    {{ header.text }}

                    <v-icon
                      v-if="header.text && !header.disableFilter"
                      small
                      :color="header.filterIsActive ? 'accent' : 'gray'"
                    >
                      mdi-filter-variant
                    </v-icon>
                  </div>
                </template>
                <FilterMenu
                  :value="header.filters"
                  :showSearch="header.showSearch"
                  :filterOptions="header.filterOptions"
                  @filterUpdate="filterUpdate($event, header)"
                  @orderBy="orderBy($event, header)"
                  @resetCalendar="resetCalendar(header)"
                  @filterSelectUpdate="filterSelectUpdate($event, header)"
                />
              </v-menu>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item.totalNetCommission="{ item }">
        {{ formatNumber(item.totalNetCommission) }}
      </template>

      <template v-slot:item.user.isLocked="{ item }">
        {{ item.user.isLocked ? "Nekativní" : "Aktivní" }}
      </template>

      <template v-slot:body.append>
        <tr class="grey lighten-4 font-weight-bold no-wrap">
          <td class="table-border-right" :colspan="footerColspan">CELKEM</td>
          <td class="text-right">
            {{
              totalSums.netCommissionSum
                ? formatNumber(totalSums.netCommissionSum)
                : totalSums.netCommissionSum
            }}
          </td>
          <td class="text-center">
            {{ totalSums.ordersSum }}
          </td>

          <td class="text-center table-border-right">
            {{ totalSums.productsSoldSum }}
          </td>
          <td class="text-center">{{ totalSums.totalPlannedSum }}</td>
          <td class="text-center">
            {{ totalSums.totalRealizedSum }}
          </td>
          <td class="table-border-right text-center">
            {{
              totalSums.totalFeasibilityPercentageSum !== null
                ? `${totalSums.totalFeasibilityPercentageSum} %`
                : "-"
            }}
          </td>
          <td class="text-center">
            {{ totalSums.omniPlannedSum }}
          </td>
          <td class="text-center">
            {{ totalSums.omniRealizedSum }}
          </td>
          <td class="text-center">
            {{
              totalSums.totalOmniFeasibilityPercentageSum !== null
                ? `${totalSums.totalOmniFeasibilityPercentageSum} %`
                : "-"
            }}
          </td>
          <td class="table-border-right text-center">
            {{ totalSums.omniMeetingsOrdersSum }}
          </td>
          <td class="text-center">
            {{ totalSums.dtbPlannedSum }}
          </td>
          <td class="text-center">
            {{ totalSums.dtbRealizedSum }}
          </td>
          <td class="text-center">
            {{
              totalSums.totalOwnFeasibilityPercentageSum !== null
                ? `${totalSums.totalDtbFeasibilityPercentageSum} %`
                : "-"
            }}
          </td>
          <td class="table-border-right text-center">
            {{ totalSums.dtbMeetingsOrdersSum }}
          </td>
          <td class="text-center">
            {{ totalSums.ownPlannedSum }}
          </td>
          <td class="text-center">
            {{ totalSums.ownRealizedSum }}
          </td>
          <td class="text-center">
            {{
              totalSums.totalOwnFeasibilityPercentageSum !== null
                ? `${totalSums.totalOwnFeasibilityPercentageSum} %`
                : "-"
            }}
          </td>
          <td class="table-border-right text-center">
            {{ totalSums.ownMeetingsOrdersSum }}
          </td>
        </tr>
      </template>
    </DataTable>
    <DataTable
      v-else
      :headers="headers"
      :items="[]"
      hide-default-header
      :metadata="meetingsTableMetadataEmpty"
      @updateFooterOptions="updateFooterOptionsMeetings"
    >
      <template v-slot:header>
        <thead>
          <tr>
            <th
              v-for="(headersTitle, i) in headersTitles"
              :key="i"
              :colspan="headersTitle.text ? 3 : 1"
              class="text-center"
              :class="i + 1 < headersTitles.length && 'table-border-right'"
            >
              {{ headersTitle.text }}
            </th>
          </tr>
          <tr>
            <th v-for="(header, i) in headers" :key="i" :class="header.class">
              {{ header.text }}
            </th>
          </tr>
        </thead>
      </template>
    </DataTable>
  </div>
</template>

<script>
import useApiCall from "../use/apiCall";
import FilterMenu from "./elements/FilterMenu.vue";
import CalendarFilterButtons from "./CalendarFilterButtons.vue";
import DataTable from "./elements/DataTable.vue";
import useFormatNumber from "../use/formatNumber";
import useDateFormating from "../use/dateFormating.js";
import InputDatePicker from "./elements/InputDatePicker.vue";

export default {
  components: { CalendarFilterButtons, DataTable, FilterMenu, InputDatePicker },
  name: "MeetingsStatistic",
  setup() {
    const { formatNumber } = useFormatNumber();
    const { todayDate } = useDateFormating();
    const { getData, getRawData } = useApiCall();
    return {
      getRawData,
      getData,
      formatNumber,
      todayDate,
    };
  },
  data() {
    return {
      skip: 0,
      take: 100,
      orderByParams: {},
      meetingsTableMetadata: {},
      meetingsTableMetadataEmpty: {
        totalRecords: 0,
      },
      startDate: null,
      endDate: null,
      loadingExportExcel: false,
      loadingExportExcelAll: false,
      requestTimeOut: null,
      filterParams: {
        userIsLocked: false,
      },
      statistics: [],
      totalSums: {},
      footerOptions: {
        itemsPerPage: 100,
        page: 0,
      },
      headersTitles: [
        {
          text: "",
        },
        {
          text: "VÝKONY",
          colspan: 3,
        },
        {
          text: "SCHŮZKY",
          colspan: 3,
        },
        {
          text: "OMNI",
          colspan: 4,
        },
        {
          text: "DTB",
          colspan: 4,
        },
        {
          text: "VLASTNÍ",
          colspan: 4,
        },
      ],
      headers: [
        {
          text: "OZ",
          value: "user.fullName",
          class: "table-border-right",
          cellClass: "table-border-right min-width-cell",
          filters: "",
          filterIsActive: false,
        },
        {
          text: "Celková provize",
          disableFilter: true,
          value: "totalNetCommission",
          class: "text-center",
          align: "right",
        },
        {
          text: "Počet objednávek",
          disableFilter: true,
          value: "totalOrdersCount",
          class: "text-center",
          align: "center",
        },
        {
          text: "Prodaných služeb",
          disableFilter: true,
          value: "totalProductsSoldCount",
          class: "table-border-right text-center",
          align: "center",
          cellClass: "table-border-right",
        },
        {
          text: "Naplánované",
          disableFilter: true,
          value: "totalPlanned",
          class: "text-center",
          align: "center",
        },
        {
          text: "Realizované",
          disableFilter: true,
          value: "totalRealized",
          class: "text-center",
          align: "center",
        },
        {
          text: "Realizovatelnost",
          disableFilter: true,
          value: "totalFeasibilityPercentage",
          class: "table-border-right text-center",
          cellClass: "table-border-right",
          align: "center",
        },
        {
          text: "Naplánované",
          disableFilter: true,
          class: "text-center",
          value: "totalOmniPlanned",
          align: "center",
        },
        {
          text: "Realizované",
          disableFilter: true,
          value: "totalOmniRealized",
          class: "text-center",
          align: "center",
        },
        {
          text: "Realizovatelnost",
          disableFilter: true,
          value: "totalOmniFeasibilityPercentage",
          class: " text-center",

          align: "center",
        },
        {
          text: "Prodaných služeb",
          disableFilter: true,
          class: "table-border-right text-center",
          cellClass: "table-border-right",
          value: "totalOmniMeetingsOrdersCount",
          align: "center",
        },
        {
          text: "Naplánované",
          disableFilter: true,
          class: "text-center",
          value: "totalDtbPlanned",
          align: "center",
        },
        {
          text: "Realizované",
          disableFilter: true,
          value: "totalDtbRealized",
          class: "text-center",
          align: "center",
        },
        {
          text: "Realizovatelnost",
          disableFilter: true,
          value: "totalDtbFeasibilityPercentage",
          align: "center",
        },
        {
          text: "Prodaných služeb",
          disableFilter: true,
          class: "table-border-right text-center",
          cellClass: "table-border-right",
          value: "totalDtbMeetingsOrdersCount",
          align: "center",
        },
        {
          text: "Naplánované",
          disableFilter: true,
          class: "text-center",
          value: "totalOwnPlanned",
          align: "center",
        },
        {
          text: "Realizované",
          disableFilter: true,
          value: "totalOwnRealized",
          align: "center",
        },
        {
          text: "Realizovatelnost",
          disableFilter: true,
          value: "totalOwnFeasibilityPercentage",
          class: "text-center",
          align: "center",
        },
        {
          text: "Prodaných služeb",
          disableFilter: true,
          value: "totalOwnMeetingsOrdersCount",
          align: "center",
        },
      ],
    };
  },
  computed: {
    userRole() {
      return this.$store.getters.getRole;
    },
    cellWidth() {
      return this.userRole === "assistant" ? "300px" : 0;
    },
    footerColspan() {
      switch (this.userRole) {
        case "admin" || "assistant":
          return 3;
        case "manager":
          return 2;
        default:
          return 0;
      }
    },
  },
  async mounted() {
    if (this.userRole === "admin" || this.userRole === "assistant") {
      this.headers.unshift({
        text: "ASM",
        value: "superiorUser.fullName",
        class: "table-border-right",
        cellClass: "table-border-right min-width-cell",
        filters: "",
        filterIsActive: false,
      });

      this.headersTitles.unshift({
        text: "",
      });
    }

    if (this.userRole === "assistant") {
      this.totalSums.netCommissionSum = "-";
    }

    const OZindex = this.headers.map((e) => e.text).indexOf("OZ");

    if (this.userRole !== "representative") {
      this.headers.splice(OZindex + 1, 0, {
        text: "Status",
        value: "user.isLocked",
        class: "table-border-right",
        cellClass: "table-border-right min-width-cell",
        showSearch: false,
        filters: false,
        filterIsActive: true,
        filterOptions: [
          { text: "Neaktivní", value: true },
          { text: "Aktivní", value: false },
        ],
      });

      this.headersTitles.unshift({
        text: "",
      });
    }
  },

  methods: {
    async fetchStatistics() {
      const userId = this.$store.getters.getUserId;
      const userRole = this.userRole;

      const request =
        userRole === "admin"
          ? {
              ...this.filterParams,
              ...this.orderByParams,
            }
          : {
              UserId: userId,

              ...this.filterParams,
              ...this.orderByParams,
            };

      const response = await this.getData(
        "statistics/meetings",
        this.skip,
        this.take,
        request
      );

      this.statistics = response.statistics;
      this.totalSums = response.statisticsSum;

      this.statistics.forEach((statistic) => {
        for (let name in statistic) {
          if (name.includes("Percentage")) {
            statistic[name] === null
              ? (statistic[name] = `-`)
              : (statistic[name] = `${statistic[name]} %`);
          }
        }
      });

      this.meetingsTableMetadata = response.metadata;
    },
    async dayButtonChange(event) {
      this.filterParams.from = event.from;
      this.filterParams.to = event.to;
      await this.fetchStatistics();
    },

    async updateFooterOptionsMeetings(event) {
      this.skip = event.page * event.itemsPerPage;
      this.take = event.itemsPerPage;
      await this.fetchStatistics();
    },

    async filterUpdate(event, header) {
      header.filters = event;
      header.filterIsActive = event;
      this.filterParams[`searchIn${this.getParamsKey(header.value)}`] = event;

      if (this.requestTimeOut) {
        clearTimeout(this.requestTimeOut);
      }
      this.requestTimeOut = setTimeout(() => {
        this.fetchStatistics();
        clearTimeout(this.requestTimeOut);
      }, 600);
    },

    async filterSelectUpdate(event, header) {
      const paramKey = this.getParamsKey(header.value);
      this.filterParams[paramKey] = event;
      header.filters = event;
      header.filterIsActive = event !== null;
      await this.fetchStatistics();
    },

    async orderBy(event, header) {
      this.headers.forEach((el) => {
        el.filterIsActive = el.filters;
      });

      header.filterIsActive = true;

      this.orderByParams.orderBy = event
        ? `${this.getParamsKey(header.value)}Asc`
        : `${this.getParamsKey(header.value)}Desc`;
      await this.fetchStatistics();
    },

    async clearAllFilters() {
      this.filterParams = {};
      this.filterParams.userIsLocked = false;
      this.orderByParams = {};
      this.headers.forEach((el) => {
        el.filters = null;
        el.filterIsActive = false;
      });

      if (this.userRole === "admin") {
        const userIsLockedFilter = this.headers.find((header) => {
          return header.value === "user.isLocked";
        });
        userIsLockedFilter.filters = false;
        userIsLockedFilter.filterIsActive = true;
      }
      this.filterParams.from = this.todayDate();
      this.filterParams.to = this.todayDate();
      this.$refs.calendarFilterButton.dayFilter = 0;
      this.$refs.inputDatePicker.clearDates();
      this.skip = 0;
      this.take = 100;
      this.$refs.meetingsStatisticTable.footerOptions.page = 0;
      this.$refs.meetingsStatisticTable.footerOptions.itemsPerPage = 100;
    },

    async saveDates(event) {
      this.filterParams.from = event[0];
      this.filterParams.to = event[1];
      if (event[0] === this.todayDate() && event[1] === this.todayDate()) {
        this.$refs.calendarFilterButton.dayFilter = 0;
      }
      await this.fetchStatistics();
    },

    async handleDownload() {
      try {
        this.loadingExportExcel = true;
        const response = await this.exportExcel();
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        const contentDisposition = response.headers["content-disposition"];
        const fileName = contentDisposition.match(/"([^']+)"/)[1];

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingExportExcel = false;
      }
    },

    async handleDownloadAll() {
      try {
        this.loadingExportExcelAll = true;
        const response = await this.exportAllExcel();
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        const contentDisposition = response.headers["content-disposition"];
        const fileName = contentDisposition.match(/"([^']+)"/)[1];

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingExportExcelAll = false;
      }
    },

    async exportExcel() {
      const userId = this.$store.getters.getUserId;
      const request =
        this.userRole === "admin"
          ? {
              ...this.filterParams,
              ...this.orderByParams,
            }
          : {
              UserId: userId,

              ...this.filterParams,
              ...this.orderByParams,
            };
      const excel = await this.getRawData(
        "statistics/meetings-export",
        this.skip,
        this.take,
        request
      );

      return excel;
    },

    async exportAllExcel() {
      const userId = this.$store.getters.getUserId;
      const request =
        this.userRole === "admin"
          ? {
              ...this.filterParams,
              ...this.orderByParams,
            }
          : {
              UserId: userId,

              ...this.filterParams,
              ...this.orderByParams,
            };
      const excel = await this.getRawData(
        "statistics/meetings-export",
        0,
        null,
        request
      );

      return excel;
    },

    getParamsKey(value) {
      switch (value) {
        case "superiorUser.fullName":
          return "superiorUserFullName";
        case "user.fullName":
          return "userFullName";
        case "user.isLocked":
          return "userIsLocked";
        default:
          return value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.meetings-statistics {
  .col:not(:last-child) {
    margin-right: 1rem;
  }

  th {
    height: 2rem !important;
  }

  ::v-deep .table-border-right {
    border-right: 1px solid gainsboro;
  }

  ::v-deep .min-width-cell {
    width: v-bind(cellWidth);
  }
}

.no-wrap {
  white-space: nowrap;
}
</style>
