<template>
  <div class="container card login">
    <h1>Přihlášení</h1>
    <v-alert
      v-if="$store.state.loginAgainText"
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
    >
      {{ $store.state.loginAgainText }}
    </v-alert>
    <LoginForm />
  </div>
</template>

<script>
import LoginForm from "../components/form/LoginForm.vue";
export default {
  components: { LoginForm },
};
</script>

<style lang="scss" scoped>
.login.card {
  max-width: 30rem;
}
</style>
