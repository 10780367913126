<template>
  <v-form
    v-if="order && categories && discounts"
    class="add-order-form"
    ref="form"
    @submit.prevent="onSubmit"
  >
    <v-container class="pa-0">
      <v-row>
        <v-col cols="12" lg="12">
          <div class="card order-card">
            <h3 class="mb-4">Objednávka</h3>
            <v-row>
              <v-col cols="2">
                <InputElement
                  type="date"
                  :min="beginOfThisMonth()"
                  :max="todayDate()"
                  label="DATUM OBJEDNÁVKY"
                  :rules="[rules.required, rules.thisMonth]"
                  :value="order.orderedAt"
                  @input="updateOrderForm('orderedAt', $event)"
                />
              </v-col>
              <v-col cols="2">
                <InputElement
                  label="ČÍSLO OBJEDNÁVKY"
                  :rules="[rules.required, rules.max200Char]"
                  :value="order.orderNumber"
                  @input="updateOrderForm('orderNumber', $event)"
                />
              </v-col>
              <v-col cols="2">
                <InputElement
                  label="TEL. ČÍSLO"
                  :rules="
                    numberIsRequired ? [rules.required, rules.max250Char] : []
                  "
                  :value="order.phoneNumber"
                  @input="updateOrderForm('phoneNumber', $event)"
                />
              </v-col>
              <v-col cols="2">
                <SelectInputElement
                  v-if="categories"
                  :items="categories"
                  item-value="id"
                  item-text="name"
                  label="SKUPINA"
                  return-object
                  :value="order.serviceId"
                  @input="categoryInputUpdate($event)"
                />
              </v-col>
              <v-col cols="2">
                <SelectInputElement
                  ref="productField"
                  :rules="[rules.required]"
                  :error-messages="
                    productFieldError ? 'Toto pole je povinné' : null
                  "
                  :items="products"
                  item-value="id"
                  return-object
                  item-text="name"
                  label="PRODUKT"
                  :value="order.product"
                  @input="productInputUpdate($event)"
                  @change="productFieldChange"
                />
              </v-col>
              <v-col cols="2">
                <v-switch
                  v-if="showIsTransfer"
                  class="mt-0"
                  label="TRANSFER"
                  :input-value="order.isTransfer"
                  :true-value="true"
                  :false-value="false"
                  inset
                  @change="updateOrderForm('isTransfer', !!$event)"
                  @click="calculateOnChange"
                ></v-switch>
              </v-col>
            </v-row>

            <h3 class="my-4">Slevy</h3>
            <v-row>
              <v-col cols="4">
                <v-radio-group
                  row
                  v-model="discountType"
                  @change="radioButtonChange"
                  class="mt-0"
                >
                  <template v-slot:label>
                    <div>Zvolte typ slevy</div>
                  </template>
                  <v-radio label="Žádná" :value="null"></v-radio>
                  <v-radio
                    v-if="order?.product?.allowO2Together"
                    label="Ve spolu"
                    value="o2Together"
                  ></v-radio>
                  <v-radio
                    v-if="showProDiscount"
                    label="Profi sleva"
                    value="proDiscount"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="2" v-if="discountType === 'o2Together'">
                <SelectInputElement
                  label="VE SPOLU"
                  :rules="[rules.required]"
                  :items="o2Together"
                  :value="order.o2TogetherLevel"
                  @input="updateOrderForm('o2TogetherLevel', $event)"
                  @change="calculateOnChange"
                />
              </v-col>
              <v-col cols="2">
                <InputElement
                  v-if="discountType === 'proDiscount'"
                  :rules="[rules.required, rules.numberBellow40]"
                  label="PROFI SLEVA"
                  suffix="%"
                  :value="order.proDiscountPercentage"
                  type="number"
                  :max="40"
                  :min="0"
                  @input="updateOrderForm('proDiscountPercentage', $event)"
                  @change="calculateOnChange"
                />

                <InputElement
                  v-if="discountType === 'proDiscount'"
                  label="LCO"
                  :value="order.lcoDiscount"
                  type="number"
                  @input="updateOrderForm('lcoDiscount', $event)"
                  @change="calculateOnChange"
                />
                <InputElement
                  v-if="discountType === 'proDiscount'"
                  label="HWB"
                  :value="order.hwbDiscount"
                  type="number"
                  @input="updateOrderForm('hwbDiscount', $event)"
                  @change="calculateOnChange"
                />

                <SelectInputElement
                  v-if="discountType !== 'proDiscount' && discounts"
                  return-object
                  clearable
                  multiple
                  label="SLEVY"
                  :items="discounts"
                  item-text="name"
                  item-value="id"
                  :value="order.discounts"
                  @input="updateOrderForm('discounts', $event)"
                  @change="calculateOnChange"
                />
              </v-col>
              <v-col cols="4">
                <v-textarea
                  :rules="[rules.max2500Char]"
                  class="mt-0"
                  hide-details="auto"
                  outlined
                  auto-grow
                  dense
                  rows="1"
                  label="KOMENTÁŘ"
                  :value="order.comment"
                  @input="updateOrderForm('comment', $event)"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h3 class="my-4">Typ schůzky</h3>
                <v-radio-group
                  row
                  v-model="orderForm.meetingType"
                  @change="meetingTypeChange"
                  :rules="[rules.required]"
                  class="mt-0"
                >
                  <template v-slot:label>
                    <div>Zvolte typ schůzky</div>
                  </template>
                  <v-radio label="OMNI" value="omni"></v-radio>
                  <v-radio label="DTB" value="dtb"></v-radio>
                  <v-radio label="Vlastní schůzka" value="own"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <div v-if="!editOrder">
              <h3 class="my-4">Souhrn</h3>
              <v-row v-if="calculatedOrder">
                <v-col>
                  <div>PROVIZE</div>
                  <span class="text-h6 primary--text">
                    {{
                      formatNumber(calculatedOrder.calculatedCurrentCommission)
                    }}
                  </span>
                  Kč
                </v-col>
                <v-col>
                  <div>SLEVY</div>
                  <span class="text-h6 primary--text">
                    {{ formatNumber(calculatedOrder.calculatedDiscountsSum) }}
                  </span>
                  Kč
                </v-col>
                <v-col>
                  <div>PROFI SLEVA</div>
                  <span class="text-h6 primary--text">
                    {{ formatNumber(calculatedOrder.calculatedProDiscount) }}
                  </span>
                  Kč
                </v-col>
                <v-col>
                  <div>LCO SLEVA</div>
                  <span class="text-h6 primary--text">
                    {{ formatNumber(calculatedOrder.calculatedLcoDiscount) }}
                  </span>
                  Kč
                </v-col>
                <v-col>
                  <div>HWB SLEVA</div>
                  <span class="text-h6 primary--text">
                    {{ formatNumber(calculatedOrder.calculatedHwbDiscount) }}
                  </span>
                  Kč
                </v-col>
                <v-col>
                  <div>ČISTÁ PROVIZE:</div>
                  <div
                    :class="negativeNetComission ? 'accent--text' : 'red--text'"
                  >
                    <span class="text-h6">
                      {{
                        formatNumber(calculatedOrder.calculatedNetCommission)
                      }}
                    </span>
                    <span> Kč </span>
                  </div>
                </v-col>
              </v-row>
            </div>

            <div v-if="!editOrder" class="mt-12">
              <v-btn depressed class="mr-4" @click="resetForm"
                >Resetovat formulář</v-btn
              >
              <v-btn type="submit" depressed color="primary" :loading="loading"
                >Uložit objednávku</v-btn
              >
            </div>
            <div v-else class="mt-12">
              <v-btn depressed class="mr-4" @click="cancelChanges"
                >Zrušit změny</v-btn
              >
              <v-btn
                type="submit"
                depressed
                color="primary"
                @click="editForm"
                :loading="editLoading"
              >
                Upravit objednávku
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import InputElement from "../elements/InputElement.vue";
import SelectInputElement from "../elements/SelectInputElement.vue";
import validation from "../../use/validation";
import useDateFormating from "../../use/dateFormating";
import useFormatNumber from "../../use/formatNumber";

export default {
  components: { InputElement, SelectInputElement },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    editLoading: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      required: true,
    },
    calculatedOrder: {
      type: Object,
      required: false,
    },
    categories: {
      type: Array,
      required: false,
    },
    discounts: {
      type: Array,
      required: false,
    },
    products: {
      type: Array,
      required: false,
    },
    o2Together: {
      type: Array,
      required: false,
    },
    editOrder: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { formatNumber } = useFormatNumber();
    const { beginOfThisMonth, todayDate } = useDateFormating();
    return {
      beginOfThisMonth,
      todayDate,
      formatNumber,
    };
  },
  data() {
    return {
      discountType: null,
      showIsTransfer: false,
      rules: validation,
      productFieldError: false,
      numberIsRequired: true,
      showProDiscount: true,
      orderForm: {
        category: null,
        orderedAt: null,
        orderNumber: null,
        phoneNumber: null,
        meetingType: null,
        product: null,
        isTransfer: null,
        o2TogetherLevel: null,
        proDiscountPercentage: null,
        comment: null,
        discounts: [],
      },
    };
  },
  mounted() {
    this.orderForm = this.order;
    if (this.order.isTransfer !== null) {
      this.showIsTransfer = true;
    }

    if (this.order.o2TogetherLevel) {
      this.discountType = "o2Together";
    } else if (
      this.order.proDiscountPercentage ||
      this.order.lcoDiscount ||
      this.order.hwbDiscount
    ) {
      this.discountType = "proDiscount";
    }
  },
  computed: {
    negativeNetComission() {
      return this.calculatedOrder.calculatedNetCommission >= 0;
    },
  },
  methods: {
    calculateOnChange() {
      if (!this.orderForm.product) {
        return;
      }
      this.$emit("calcuteForm");
    },
    productFieldChange() {
      this.orderForm.o2TogetherLevel = null;
      this.orderForm.discountType = null;
      if (!this.orderForm.product) {
        return;
      }
      this.$emit("calcuteForm");
    },
    onSubmit() {
      const formIsValid = this.$refs.form.validate();
      if (!formIsValid || this.productFieldError) {
        return;
      }
      this.$emit("orderFormSubmit");
    },
    updateOrderForm(key, event) {
      this.orderForm[key] = event;
      this.$emit("orderForm", this.orderForm);
    },
    radioButtonChange() {
      this.$emit("orderForm", this.orderForm);
      this.$emit("discountTypeChange");
    },

    meetingTypeChange() {
      this.$emit("orderForm", this.orderForm);
    },
    categoryInputUpdate(event) {
      this.numberIsRequired = true;
      this.showProDiscount = true;
      if (event?.type === "vasHw") {
        this.numberIsRequired = false;
        this.showProDiscount = false;
      }
      if (event?.type === "dvpt") {
        this.showProDiscount = false;
      }
      this.discountType = null;
      if (this.orderForm.product) {
        this.orderForm.product = null;
        this.showIsTransfer = false;
        this.orderForm.isTransfer = null;
      }
      this.$emit("orderForm", this.orderForm);
      this.$emit("discountTypeChange");
      this.$emit("selectCategory", event);
    },
    productInputUpdate(event) {
      this.productFieldError = false;
      this.orderForm.product = event;
      this.showIsTransfer = false;
      this.orderForm.isTransfer = null;
      this.discountType = null;
      this.showProDiscount = true;
      if (
        event?.productCategoryName === "DVPT" ||
        event?.productCategoryName === "VAS_HW"
      ) {
        this.showProDiscount = false;
      }

      if (
        event?.productCategoryId === 1 ||
        event?.productCategoryId === 5 ||
        event?.productCategoryId === 9
      ) {
        this.showIsTransfer = true;
        this.orderForm.isTransfer = false;
        this.$emit("orderForm", this.orderForm);
        this.$emit("discountTypeChange");
        return;
      }

      this.$emit("orderForm", this.orderForm);
      this.$emit("discountTypeChange");
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.$emit("resetForm");
    },

    editForm() {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      // this.updateOrderForm();
      this.$emit("editForm");
    },

    cancelChanges() {
      this.orderForm = this.order;
      this.$emit("cancelChanges");
    },

    syncForm() {
      this.orderForm = this.order;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-card {
  position: relative;
  z-index: 1;
}
</style>
