<template>
  <div class="verify-email container card">
    <h1>Dokončení registrace</h1>
    <verify-email-form />
  </div>
</template>

<script>
import VerifyEmailForm from "../components/form/VerifyEmailForm.vue";
export default {
  components: { VerifyEmailForm },
};
</script>

<style lang="scss" scoped>
.verify-email {
  max-width: 30rem;
}
</style>
