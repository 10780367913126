<template>
  <v-form
    v-model="valid"
    class="login-form"
    @submit.prevent="onSubmit"
    ref="form"
  >
    <InputElement
      :rules="[rules.required]"
      label="Osobní číslo"
      v-model="credentials.personalNumber"
    />
    <PasswordInputElement
      :rules="[rules.required]"
      label="Heslo"
      type="password"
      v-model="credentials.password"
    />
    <v-btn depressed color="primary" type="submit" class="mb-4" :loading="loading"
      >Přihlásit se</v-btn
    >
    <div class="text-center">
      <router-link
        to="/zapomenute-heslo"
        class="primary--text text-center cursor-pointer"
        >Zapomenuté heslo</router-link
      >
    </div>
    <div class="text-center mt-4">
      <router-link
        to="/potvrzeni-emailu"
        class="primary--text text-center cursor-pointer"
        >Zaslat potvrzovací email znovu</router-link
      >
    </div>
  </v-form>
</template>

<script>
import InputElement from "../elements/InputElement.vue";
import PasswordInputElement from "../elements/PasswordInputElement.vue";
import useApiCall from "@/use/apiCall";
import validation from "../../use/validation";

export default {
  name: "LoginForm",
  components: {
    PasswordInputElement,
    InputElement,
  },

  setup() {
    const { postData } = useApiCall();
    return {
      postData,
    };
  },

  data() {
    return {
      valid: true,
      credentials: {
        personalNumber: null,
        password: null,
      },
      token: null,
      rules: validation,
      loading: false,
    };
  },

  methods: {
    async onSubmit() {
      this.loading = true;
      const user = await this.postData(
        "/auth",
        {
          credentials: this.credentials,
        },
        null,
        true
      );
      this.loading = false;
      if (user) {
        this.$store.commit("setUserInfo", user);
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  width: 100%;
}
</style>
