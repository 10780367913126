<template>
  <div class="page-not-found container text-center">
    <h1>Stránka nenalezena</h1>
    <div>Bohužel jsme nenašli požadovanou stránku.</div>
    <v-img
      src="../assets/img/404-animation.gif"
      max-width="200"
      class="ma-auto"
    ></v-img>
    <div>
      <v-btn
        color="primary"
        depressed
        @click="
          $router.push({
            name: 'Home',
          })
        "
        >Zpět na hlavní stránku</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
