import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import apiCall from '../use/apiCall'

Vue.use(Vuex, VuexPersistence);

export default new Vuex.Store({
  state: {
    userInfo: null,
    formMessages: {
      type: 'error',
      texts: null,
    },
    loginAgainText: '',
    categories: [],
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setFormMessages(state, formMessages) {
      state.formMessages = formMessages;
    },
    setFormMessagesTexts(state, texts) {
      state.formMessages.texts = texts;
    },
    setFormMessagesType(state, type) {
      state.formMessages.type = type;
    },
    setAuth(state, auth) {
      state.userInfo.auth = auth;
    },
    setToken(state, token) {
      state.userInfo.auth.accessToken = token;
    },
    setTokenExpiration(state, tokenExpiration) {
      state.userInfo.auth.accessTokenExpiresAt = tokenExpiration;
    },
    setLoginAgainText(state, loginAgainText) {
      state.loginAgainText = loginAgainText;
    },
    setCategories(state, categories) {
      state.categories = categories;
    }
  },
  getters: {
    getToken(state) {
      return state?.userInfo?.auth?.accessToken;
    },
    getTokenExpiration(state) {
      return state?.userInfo?.auth?.accessTokenExpiresAt;
    },
    getRole(state) {
      return state?.userInfo?.user?.role;
    },
    getfirstNameSalutation(state) {
      return state?.userInfo?.user?.firstNameSalutation;
    },
    getUserId(state) {
      return state?.userInfo?.user?.id;
    },
  },
  actions: {
    async fetchCategories() {
      const {getData} = apiCall()
      const categories = await getData('product-categories/?skip=0&take=10000&isHidden=false&orderBy=nameAsc')
      return categories;
    }
  },
  modules: {},
  plugins: [new VuexPersistence().plugin],
});
