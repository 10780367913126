<template>
  <div class="form-message" v-if="this.messages">
    <v-alert
      :value="alertValue"
      transition="scale-transition"
      :type="messages.type"
      dismissible
      close-icon="mdi-close"
      prominent
      class="mb-6"
    >
      <div v-for="(item, i) in messages.texts" :key="i">
        {{ item }}
      </div>
    </v-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      alertValue: false,
      timeOut: null,
    };
  },
  computed: {
    messages() {
      return this.$store.state.formMessages;
    },

    messageIsArray() {
      return Array.isArray(this.messages.texts);
    },
  },

  watch: {
    messageIsArray() {
      if (this.messageIsArray) {
        this.alertValue = true;
        if (this.timeOut) {
          clearTimeout(this.timeOut);
        }

        this.timeOut = setTimeout(() => {
          this.alertValue = false;
          this.timeOut = null;
        }, 6000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-message {
  position: fixed;
  right: 0;
  max-width: 25rem;
  z-index: 1000;

  .success {
    ::v-deep .v-alert__icon,
    ::v-deep .v-alert__content,
    ::v-deep,
    ::v-deep .v-alert__dismissible {
      color: #4caf50;
    }
    background-color: #d1eed3 !important;
  }

  .error {
    ::v-deep .v-alert__icon,
    ::v-deep .v-alert__content,
    ::v-deep,
    ::v-deep .v-alert__dismissible {
      color: #ff5252;
    }
    background-color: #ffe1e1 !important;
  }
}
</style>