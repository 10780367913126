<template>
  <v-app>
    <app-bar />
    <v-main>
      <FormMessage />
      <router-view />
    </v-main>
    <div class="text-center mb-4">DKtel s.r.o.© {{ currentYear }}</div>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";
import FormMessage from "./components/form/FormMessage.vue";
export default {
  components: { AppBar, FormMessage },
  name: "App",
  computed: {
    currentYear() {
      const today = new Date();
      const year = today.getFullYear();
      return year;
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: TitiliumRegular;
  src: url("./assets/fonts/TitilliumWeb-Regular.ttf");
}

.v-application {
  background: whitesmoke !important;
  font-family: TitiliumRegular, sans-serif;

  h1 {
    margin-bottom: 1rem;
  }

  .v-main {
    padding-top: 6rem !important;
  }

  .container {
    max-width: 1800px;
  }

  .card {
    border: 1px solid gainsboro;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: white;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .search-input {
    max-width: 25rem;
  }
}
</style>
