export default function () {
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-');
  }

  function todayDate() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return formatDate(date);
  }

  function yesterdayDate() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const diff = date.getDate() - 1;
    const yeseterday = new Date(date.setDate(diff));
    return formatDate(yeseterday);
  }

  function beginOfThisWeek() {
    const date = new Date();
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    const monday = new Date(date.setDate(diff));

    return formatDate(monday);
  }
  function endOfThisWeek() {
    const date = new Date();
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    const monday = new Date(date.setDate(diff));

    const sunday = new Date(monday);
    sunday.setDate(sunday.getDate() + 6);
    return formatDate(sunday);
  }

  function beginOfLastWeek() {
    const date = new Date();
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    const thisMonday = new Date(date.setDate(diff));

    const diffMonday = thisMonday.getDate(day) - 7;
    const lastMonday = new Date(date.setDate(diffMonday));
    return formatDate(lastMonday);
  }
  function endOfLastWeek() {
    const date = new Date();
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    const thisMonday = new Date(date.setDate(diff));

    const diffMonday = thisMonday.getDate(day) - 7;
    const lastMonday = new Date(date.setDate(diffMonday));

    const lastSunday = new Date(lastMonday);
    lastSunday.setDate(lastSunday.getDate() + 6);

    return formatDate(lastSunday);
  }

  function beginOfThisMonth() {
    function getFirstDayOfMonth(year, month) {
      return new Date(year, month, 1);
    }

    const date = new Date();
    const firstDayCurrentMonth = getFirstDayOfMonth(
      date.getFullYear(),
      date.getMonth()
    );

    return formatDate(firstDayCurrentMonth);
  }

  function endOfThisMonth() {
    function getLastDayOfMonth(year, month) {
      return new Date(year, month + 1, 0);
    }

    const date = new Date();
    const lastDayCurrentMonth = getLastDayOfMonth(
      date.getFullYear(),
      date.getMonth()
    );

    return formatDate(lastDayCurrentMonth);
  }

  function beginOfLastMonth() {
    const date = new Date();
    const firstDayOfLastMonth = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      1
    );
    return formatDate(firstDayOfLastMonth);
  }

  function endOfLastMonth() {
    const date = new Date();
    const lastDayOfLastMonth = new Date(date.getFullYear(), date.getMonth(), 0);

    return formatDate(lastDayOfLastMonth);
  }

  function getShortDate(date) {
    const dateArray = date.split('-');
    const day = dateArray[2];
    const month = dateArray[1];
    const year = dateArray[0];
    const shortYear = year.substring(2, 4);

    return `${day}/${month}/${shortYear}`;
  }

  function getCzechDate(date) {
    const formatedDate = new Date(date);
    return formatedDate.toLocaleString('cs');
  }

  return {
    todayDate,
    beginOfThisWeek,
    endOfThisWeek,
    beginOfLastWeek,
    endOfLastWeek,
    beginOfThisMonth,
    endOfThisMonth,
    beginOfLastMonth,
    endOfLastMonth,
    getShortDate,
    yesterdayDate,
    getCzechDate,
  };
}
